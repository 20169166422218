import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React from "react";
import { useTranslation } from "react-i18next";

import { useStellarContext } from "../../contexts/StellarContextProvider";
import { ListContainer, TableTitles } from "../ReusableStyles";
import EffectRow from "./components/EffectRow/EffectRow";
import * as S from "./EffectsTable.styles";

type EffectsTableProps = {
  effects?: ServerApi.CollectionPage<ServerApi.EffectRecord>;
};

const EffectsTable = (p: EffectsTableProps) => {
  const { screenWidth } = useStellarContext();

  const { t } = useTranslation();

  return (
    <>
      <TableTitles>
        <S.TypeText>{t("type")}</S.TypeText>
        <S.DetailsText>{t("details")}</S.DetailsText>
        {screenWidth > 460 && (
          <S.TransactionText>{screenWidth < 600 ? t("tsx") : t("transaction")}</S.TransactionText>
        )}
        <S.TimeText>{t("time")}</S.TimeText>
        <S.JsonFileText>{screenWidth < 390 ? t("json") : t("json.file")}</S.JsonFileText>
      </TableTitles>
      <ListContainer>
        {p.effects?.records.map((effect, index) => (
          <EffectRow
            effect={effect}
            index={index}
            recordsLength={p.effects?.records.length || 0}
            key={effect.id}
          />
        ))}
      </ListContainer>
    </>
  );
};

export default EffectsTable;
