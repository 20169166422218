import styled from "styled-components";

import { SectionContainer } from "../../Home.styles";

export const Container = styled(SectionContainer)`
  width: 65%;

  @media (max-width: 1050px) {
    width: 100%;
  }
`;
