import { StrKey } from "@stellar/stellar-sdk";
import { HorizonApi } from "@stellar/stellar-sdk/lib/horizon";
import snakeCase from "lodash.snakecase";
import React from "react";
import { Trans } from "react-i18next";
import { CSSProperties } from "styled-components";

import AccountLink from "../../../../../components/AccountLink/AccountLink";
import { isPublicKey, shortenString } from "../../../../../utils/links";

const optionsKeys: (keyof HorizonApi.SetOptionsOperationResponse)[] = [
  "home_domain",
  "signer_key",
  "signer_weight",
  "master_key_weight",
  "set_flags_s",
  "clear_flags_s",
  "low_threshold",
  "med_threshold",
  "high_threshold",
];

const getTranslationsKeyFormat = (key: string) => snakeCase(key).replace("_", ".");

const textStyle: CSSProperties = {
  whiteSpace: "normal",
  wordWrap: "break-word",
  overflowWrap: "break-word",
};

const OptionValue = ({ optionKey, value }: { optionKey: any; value: any }) => {
  if (value instanceof Array)
    return (
      <span style={textStyle} title={value.join(", ")}>
        {value.join(", ")}
      </span>
    );

  if ((optionKey === "signerKey" && isPublicKey(value)) || optionKey === "inflationDest")
    return <AccountLink accountAddress={value} variant="text" />;

  if (optionKey === "signerKey") {
    const decodedValue =
      value.charAt(0) === "X"
        ? StrKey.decodeSha256Hash(value).toString("hex")
        : StrKey.decodePreAuthTx(value).toString("hex");
    return (
      <span style={textStyle} title={decodedValue}>
        {shortenString(decodedValue)}
      </span>
    );
  }

  if (optionKey === "homeDomain") {
    return (
      <a style={textStyle} href={`https://${value}`}>
        {value}
      </a>
    );
  }
  return (
    <span style={textStyle} title={value}>
      {value}
    </span>
  );
};

type OptionsListProps = {
  operation: HorizonApi.SetOptionsOperationResponse;
};

export const OptionsList = (p: OptionsListProps) => (
  <span>
    {optionsKeys.map((prop, index, all) => {
      if (!p.operation[prop]) return <></>;

      return (
        <span key={prop}>
          <Trans
            i18nKey={`operation.options.set.${getTranslationsKeyFormat(prop)}`}
            components={{
              value: <OptionValue optionKey={prop} value={p.operation[prop]} />,
            }}
          />
          {index < all.length - 1 && ", "}
        </span>
      );
    })}
  </span>
);
