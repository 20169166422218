import arFile from "./languages/ar.json";
import bnFile from "./languages/bn.json";
import caFile from "./languages/ca.json";
import enFile from "./languages/en.json";
import esFile from "./languages/es.json";
import frFile from "./languages/fr.json";
import hauFile from "./languages/hau.json";
import hiFile from "./languages/hi.json";
import idFile from "./languages/id.json";
import itFile from "./languages/it.json";
import jaFile from "./languages/ja.json";
import neFile from "./languages/ne.json";
import ptFile from "./languages/pt.json";
import ruFile from "./languages/ru.json";
import urFile from "./languages/ur.json";
import viFile from "./languages/vi.json";
import zhHansFile from "./languages/zh-Hans.json";
import zhHantFile from "./languages/zh-Hant.json";

export const resources = {
  ar: { translation: arFile },
  bn: { translation: bnFile },
  ca: { translation: caFile },
  en: { translation: enFile },
  es: { translation: esFile },
  fr: { translation: frFile },
  hau: { translation: hauFile },
  hi: { translation: hiFile },
  id: { translation: idFile },
  it: { translation: itFile },
  ja: { translation: jaFile },
  ne: { translation: neFile },
  pt: { translation: ptFile },
  ru: { translation: ruFile },
  ur: { translation: urFile },
  vi: { translation: viFile },
  "zh-Hans": { translation: zhHansFile },
  "zh-Hant": { translation: zhHantFile },
};
