import isNaN from "lodash.isnan";
import isString from "lodash.isstring";
import toNumber from "lodash.tonumber";

import StellarSdk from "../../data/stellarInit";
import {
  checkIfMuxedAddress,
  isFederatedAddress,
  isPublicKey,
  isSecretKey,
  isTransactionHash,
} from "../../utils/links";

export const transformSearchPhraseToPath = (searchPhrase: string, environment?: string) => {
  if (!isString(searchPhrase) || searchPhrase.trim() === "") return null;

  const formattedString = searchPhrase.trim();

  if (
    isPublicKey(formattedString) ||
    isFederatedAddress(formattedString) ||
    checkIfMuxedAddress(formattedString)
  )
    return `/${environment}/accounts/${formattedString}`;

  if (isTransactionHash(formattedString)) return `/${environment}/transactions/${formattedString}`;

  if (!isNaN(toNumber(formattedString))) return `/${environment}/blocks/${toNumber(formattedString)}`;

  if (isSecretKey(formattedString)) {
    const keyPair = StellarSdk.Keypair.fromSecret(formattedString);

    return `/${environment}/accounts/${keyPair.publicKey()}`;
  }

  return `/${environment}/not-found`;
};
