import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { TransactionCallBuilder } from "@stellar/stellar-sdk/lib/horizon/transaction_call_builder";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStellarContext } from "../../contexts/StellarContextProvider";

export type TransactionsFor = "block" | "account";

type GetTransactionsProps = {
  transactionsLimit?: number;
  recordId?: string;
  transactionsFor?: TransactionsFor;
};

export const useGetTransactions = ({
  transactionsLimit,
  recordId,
  transactionsFor,
}: GetTransactionsProps) => {
  const { stellarServer } = useStellarContext();

  const [server, setServer] = useState<TransactionCallBuilder | undefined>();
  const [transactions, setTransactions] = useState<
    ServerApi.CollectionPage<ServerApi.TransactionRecord> | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    const baseServer = stellarServer?.transactions();
    const blockTransactionsServer = stellarServer?.transactions().forLedger(recordId || "");
    const accountTransactionsServer = stellarServer?.transactions().forAccount(recordId || "");

    let pickedServer = baseServer;
    if (transactionsFor === "block") pickedServer = blockTransactionsServer;
    if (transactionsFor === "account") pickedServer = accountTransactionsServer;

    setServer(pickedServer);
  }, [transactionsFor, recordId, stellarServer]);

  useEffect(() => {
    setIsLoading(true);
    setTransactions(undefined);
    setFetchingError(null);

    (async () => {
      try {
        const fetchedOperations = await server
          ?.limit(transactionsLimit ?? 11)
          .order("desc")
          .call();

        setTransactions(fetchedOperations);
      } catch (error: any) {
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();

    setPage(1);
  }, [transactionsLimit, server, t]);

  return {
    transactions,
    setTransactions,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  };
};
