import React from "react";
import { useTranslation } from "react-i18next";

import BlocksTable from "../components/BlocksTable/BlocksTable";
import LoaderWrapper from "../components/LoaderWrapper/LoaderWrapper";
import PagePagination from "../components/PagePagination";
import { MainContainer, TableHeader, Title } from "../components/ReusableStyles";
import { useGetBlocks } from "../data/services/useGetBlocks";

const DEFAULT_MAX_TRANSACTIONS = 20;

type BlocksProps = {
  maxTransactionsPerPage?: number;
};

const Blocks = (p: BlocksProps) => {
  const {
    blocks,
    setBlocks,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  } = useGetBlocks(20);

  const { t } = useTranslation();

  return (
    <MainContainer>
      <TableHeader>
        <Title>{t("ledgers")}</Title>
      </TableHeader>
      <LoaderWrapper loading={isLoading} fetchingError={fetchingError}>
        <PagePagination
          maxRecordsPerPage={p.maxTransactionsPerPage ?? DEFAULT_MAX_TRANSACTIONS}
          data={blocks}
          setData={setBlocks}
          page={page}
          setPage={setPage}
          fetchingError={fetchingError}
          setFetchingError={setFetchingError}
          server={server}
          setIsLoading={setIsLoading}
        >
          <BlocksTable blocks={blocks} expanded />
        </PagePagination>
      </LoaderWrapper>
    </MainContainer>
  );
};

export default Blocks;
