import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import { useStellarContext } from "../../contexts/StellarContextProvider";
import { shortenString } from "../../utils/links";
import AccountLink from "../AccountLink/AccountLink";
import CustomInternalLink from "../CustomInternalLink/CustomInternalLink";
import { ListContainer, ListRow, TableTitles } from "../ReusableStyles";
import * as S from "./TransactionsTable.styles";
import { SourceWrapper } from "./TransactionsTable.styles";

type TransactionsTableProps = {
  transactions?: ServerApi.CollectionPage<ServerApi.TransactionRecord>;
  expanded?: boolean;
  hideBlocks?: boolean;
  hideSource?: boolean;
  hideTitle?: boolean;
};

const TransactionsTable = (p: TransactionsTableProps) => {
  const { environment } = useParams<{ environment: string }>();
  const { screenWidth } = useStellarContext();

  const { t } = useTranslation();

  return (
    <>
      <TableTitles>
        <S.HashMark $expanded={p.expanded}>#</S.HashMark>
        {p.expanded && !p.hideSource && screenWidth > 500 && (
          <S.SourceText>{t("source.account")}</S.SourceText>
        )}
        {!p.hideBlocks && screenWidth > 430 && (
          <S.BlockText $expanded={p.expanded}>{t("ledger")}</S.BlockText>
        )}
        <S.OpsText $expanded={p.expanded}>
          {t(p.expanded && screenWidth > 650 ? "operations" : "ops")}
        </S.OpsText>
        <S.TimeText $expanded={p.expanded}>{t("time")}</S.TimeText>
      </TableTitles>
      <ListContainer>
        {p.transactions?.records.map((transaction, index) => (
          <ListRow $isLast={index === (p.transactions?.records.length || 0) - 1} key={transaction.id}>
            <S.HashWrapper $expanded={p.expanded}>
              <CustomInternalLink
                to={`/${environment}/transactions/${transaction.hash}`}
                title={transaction.hash}
                monospace
              >
                {p.expanded ? transaction.hash : shortenString(transaction.hash, 9)}
              </CustomInternalLink>
              {/*{transaction.hash}*/}
            </S.HashWrapper>
            {p.expanded && !p.hideSource && screenWidth > 500 && (
              <SourceWrapper>
                <AccountLink accountAddress={transaction.source_account} variant="button" />
              </SourceWrapper>
            )}
            {!p.hideBlocks && screenWidth > 430 && (
              <S.BlockWrapper $expanded={p.expanded}>
                <CustomInternalLink
                  to={`/${environment}/blocks/${transaction.ledger_attr}`}
                  title={transaction.ledger_attr.toString()}
                >
                  {transaction.ledger_attr}
                </CustomInternalLink>
              </S.BlockWrapper>
            )}
            <S.OperationWrapper $expanded={p.expanded}>
              <CustomInternalLink
                to={`/${environment}/transactions/${transaction.hash}`}
                title={transaction.hash}
              >
                {transaction.operation_count}
              </CustomInternalLink>
            </S.OperationWrapper>
            <S.TimeWrapper $expanded={p.expanded}>
              <CustomInternalLink
                to={`/${environment}/transactions/${transaction.hash}`}
                title={transaction.hash}
              >
                <ReactTimeAgo date={new Date(transaction.created_at)} locale="en-US" timeStyle="round" />
              </CustomInternalLink>
            </S.TimeWrapper>
          </ListRow>
        ))}
      </ListContainer>
    </>
  );
};

export default TransactionsTable;
