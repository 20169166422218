import { lightThemeColors } from "./colors";

export const themeBase = {
  palette: lightThemeColors,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },

  zIndex: {
    "-1": -1,
    "0": 0,
    "2": 2,
    "5": 5,
    "10": 10,
    "20": 20,
    "30": 30,
    "40": 40,
    "50": 50,
  },

  fontSize: {
    xs: "10px",
    s: "12px",
    base: "14px",
    m: "16px",
    lg: "18px",
    xl: "20px",
    xxl: "24px",
    "3xl": "26px",
    "4xl": "28px",
    "5xl": "48px",
  },

  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },

  fontWeight: {
    light: 300,
    normal: 400,
    neutral: 500,
    semibold: 600,
    bold: 700,
    extraBold: 900,
  },

  spacing: (spacing: number) => `${spacing * 2}px`,
};
