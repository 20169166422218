import Papa from "papaparse";

import { Records } from "../data/services/usePagination";

function isPrimitive(value: any): boolean {
  return value === null || typeof value !== "function";
}

function removeFunctions(obj: any): any {
  const cleanedObj: any = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      // Keep the key only if the value is different from function
      if (isPrimitive(value)) {
        cleanedObj[key] = value;
      }
    }
  }

  return cleanedObj;
}
export const downloadRecordsAsCSV = async <T extends Records>(records: T[]) => {
  const cleanedTransactions = records.map(tx => removeFunctions(tx));

  const flatData = cleanedTransactions.map((record: T) => {
    // Create a new object excluding the specified fields
    const { paging_token, _links, ...rest } = record; // Destructure to exclude fields
    return rest; // Return the remaining fields
  });

  // Convert to CSV using papaparse
  const csv = Papa.unparse(flatData);

  // Same blob and download logic as before
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "transactions-.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
