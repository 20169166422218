import { restClient } from "../axiosInit";

export type MainnetMetricsType = {
  total_circulating_supply: string | number;
  total_locked: string | number;
};

export const getMainnetMetrics = async (): Promise<MainnetMetricsType> => {
  try {
    const response = await restClient.get("https://socialchain.app/mainnet_supply");

    return {
      total_circulating_supply: response.data.total_circulating_supply || "n/a",
      total_locked: response.data.total_locked || "n/a",
    };
  } catch {
    return {
      total_circulating_supply: "n/a",
      total_locked: "n/a",
    };
  }
};
