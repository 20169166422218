import React from "react";
import { useTranslation } from "react-i18next";

import { useGetOffers } from "../data/services/useGetOffers";
import ExportCsv from "./ExportCSV/ExportCSV";
import LoaderWrapper from "./LoaderWrapper/LoaderWrapper";
import OffersTable from "./OffersTable/OffersTable";
import PagePagination from "./PagePagination";
import { MainContainer, TableHeader, Title } from "./ReusableStyles";

const DEFAULT_MAX_OFFERS = 10;

type OffersProps = {
  maxOffersPerPage?: number;
  accountId: string;
};

const Offers = (p: OffersProps) => {
  const {
    offers,
    setOffers,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  } = useGetOffers({ offersLimit: p.maxOffersPerPage ?? DEFAULT_MAX_OFFERS, accountId: p.accountId });

  const { t } = useTranslation();

  return (
    <MainContainer>
      <TableHeader>
        <Title>{t("offers")}</Title>
      </TableHeader>
      <LoaderWrapper
        loading={!offers || isLoading}
        fetchingError={fetchingError}
        collectionEmpty={offers && offers.records.length < 1}
      >
        <PagePagination
          maxRecordsPerPage={p.maxOffersPerPage ?? DEFAULT_MAX_OFFERS}
          data={offers}
          setData={setOffers}
          page={page}
          setPage={setPage}
          fetchingError={fetchingError}
          setFetchingError={setFetchingError}
          server={server}
          setIsLoading={setIsLoading}
        >
          <OffersTable offers={offers} />
        </PagePagination>
      </LoaderWrapper>
      {offers && offers.records.length > 0 && (
        <ExportCsv recordsType={"offersForAccount"} recordId={p.accountId} />
      )}
    </MainContainer>
  );
};

export default Offers;
