import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import CustomButton from "../../../components/CustomButton/CustomButton";
import LoaderWrapper from "../../../components/LoaderWrapper/LoaderWrapper";
import { Title, TitleRow } from "../../../components/ReusableStyles";
import TransactionsTable from "../../../components/TransactionsTable/TransactionsTable";
import { useGetTransactions } from "../../../data/services/useGetTransactions";
import { SectionContainer } from "../Home.styles";

const TransactionsPanel = () => {
  const { environment } = useParams<{ environment: string }>();
  const { transactions, isLoading, fetchingError } = useGetTransactions({});

  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToAllTransactions = () => {
    navigate(`/${environment}/transactions`);
  };

  return (
    <SectionContainer>
      <TitleRow>
        <Title>{t("latest.txs")}</Title>
        <CustomButton label={t("view.all")} variant="viewAll" onClick={goToAllTransactions} />
      </TitleRow>
      <LoaderWrapper loading={isLoading} fetchingError={fetchingError}>
        <TransactionsTable transactions={transactions} />
      </LoaderWrapper>
    </SectionContainer>
  );
};

export default TransactionsPanel;
