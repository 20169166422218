import { Outlet } from "react-router-dom";

import SearchBar from "../SearchBar/SearchBar";
import Header from "./components/Header/Header";
import { BodyContainer, MainContainer, ScrollContainer } from "./Layout.styles";

const Layout = () => {
  return (
    <MainContainer>
      <Header />
      <ScrollContainer>
        <BodyContainer>
          <SearchBar />
          <Outlet />
        </BodyContainer>
      </ScrollContainer>
    </MainContainer>
  );
};

export default Layout;
