import styled from "styled-components";

export const TypeWrapper = styled.div`
  flex: 1;
  min-width: 50px;
`;

export const DetailsWrapper = styled(TypeWrapper)``;

export const TransactionWrapper = styled(TypeWrapper)`
  flex: 0.5;

  @media (max-width: 1000px) {
    flex: 1;
  }
`;

export const TimeWrapper = styled(TypeWrapper)`
  flex: 0.5;

  @media (max-width: 1000px) {
    flex: 1;
  }
`;

export const JsonWrapper = styled(TypeWrapper)`
  display: flex;
  flex: 0.5;

  @media (max-width: 1000px) {
    flex: 1;
  }
`;
