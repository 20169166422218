import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { resources } from "../../translations/resources";
import { Backdrop } from "../ReusableStyles";
import {
  Language,
  LanguageChangeIcon,
  LanguageIconWrapper,
  LanguagePickerContainer,
  LanguagesList,
} from "./LanguagePicker.styles";
import { languagesList } from "./languages";

const LanguagePicker = () => {
  const [isLangListOpen, setIsLangListOpen] = useState(false);

  const { i18n } = useTranslation();

  const toggleLangList = () => {
    setIsLangListOpen(!isLangListOpen);
  };

  const changeLanguage = (lngCode: keyof typeof resources) => {
    i18n.changeLanguage(lngCode);

    setIsLangListOpen(false);
  };

  return (
    <>
      {isLangListOpen && <Backdrop onClick={toggleLangList} />}
      <LanguagePickerContainer>
        <LanguageIconWrapper onClick={toggleLangList} $isLangListOpen={isLangListOpen}>
          <LanguageChangeIcon $isLangListOpen={isLangListOpen} />
        </LanguageIconWrapper>
        {isLangListOpen && (
          <LanguagesList>
            {languagesList.map(lng => (
              <Language onClick={() => changeLanguage(lng.code)} key={lng.code}>
                {lng.displayedName}
              </Language>
            ))}
          </LanguagesList>
        )}
      </LanguagePickerContainer>
    </>
  );
};

export default LanguagePicker;
