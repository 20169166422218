import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { EffectCallBuilder } from "@stellar/stellar-sdk/lib/horizon/effect_call_builder";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStellarContext } from "../../contexts/StellarContextProvider";

type GetTransactionsProps = {
  effectsLimit: number;
  accountId?: string;
};

export const useGetEffects = ({ effectsLimit, accountId }: GetTransactionsProps) => {
  const { stellarServer } = useStellarContext();

  const [server, setServer] = useState<EffectCallBuilder | undefined>();
  const [effects, setEffects] = useState<ServerApi.CollectionPage<ServerApi.EffectRecord> | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    const baseServer = stellarServer?.effects().forAccount(accountId || "");

    setServer(baseServer);
  }, [stellarServer, accountId]);

  useEffect(() => {
    setIsLoading(true);
    setEffects(undefined);
    setFetchingError(null);

    (async () => {
      try {
        const fetchedEffects = await server
          ?.limit(effectsLimit ?? 10)
          .order("desc")
          .call();

        setEffects(fetchedEffects);
      } catch (error: any) {
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();

    setPage(1);
  }, [effectsLimit, server, t]);

  return {
    effects,
    setEffects,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  };
};
