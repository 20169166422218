import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useStellarContext } from "../../../../contexts/StellarContextProvider";
import CustomButton from "../../../CustomButton/CustomButton";
import LanguagePicker from "../../../LanguagePicker/LanguagePicker";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import {
  BlockexplorerText,
  ButtonsContainer,
  LogoContainer,
  NavBarContainer,
  NavigationContainer,
  OptionsContainer,
  PiLogoIcon,
  PiText,
} from "./Header.styles";
import HeaderTabs from "./HeaderTabs/HeaderTabs";

const Header = () => {
  const { environment } = useParams<{ environment: string }>();
  const { screenWidth } = useStellarContext();

  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const switchEnvironment = (environment?: string) => {
    navigate(`/${environment}/${location.pathname.split("/").slice(2).join("/")}`);
  };

  const showHamburgerMenu = screenWidth < 1070;

  return (
    <NavBarContainer>
      <NavigationContainer>
        <LogoContainer to={`/${environment}/`}>
          <PiLogoIcon />
          <PiText>{t("pi")}</PiText>
          <BlockexplorerText>{t("blockExplorer")}</BlockexplorerText>
        </LogoContainer>
        {!showHamburgerMenu && <HeaderTabs />}
      </NavigationContainer>
      {!showHamburgerMenu && (
        <OptionsContainer>
          <ButtonsContainer>
            <CustomButton
              label={t("testnet").toUpperCase()}
              onClick={() => switchEnvironment("testnet")}
              active={environment === "testnet"}
            />
            <CustomButton
              label={t("testnet2").toUpperCase()}
              onClick={() => switchEnvironment("testnet2")}
              active={environment === "testnet2"}
            />
            <CustomButton
              label={t("mainnet").toUpperCase()}
              onClick={() => switchEnvironment("mainnet")}
              active={environment === "mainnet"}
            />
          </ButtonsContainer>
          <LanguagePicker />
        </OptionsContainer>
      )}
      {showHamburgerMenu && (
        <OptionsContainer>
          <LanguagePicker />
          <HamburgerMenu switchEnvironment={switchEnvironment} />
        </OptionsContainer>
      )}
    </NavBarContainer>
  );
};

export default Header;
