import { animated } from "react-spring";
import styled from "styled-components";

import { ReactComponent as CloseIconImage } from "../../assets/icons/closeIcon.svg";

export const ModalBackdrop = styled(animated.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const StyledModal = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  max-height: 80vh;
  max-width: 80vw;
  padding: ${({ theme }) => `${theme.spacing(20)} ${theme.spacing(16)}`};
  overflow-y: scroll;
`;

export const CloseIcon = styled(CloseIconImage)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;
