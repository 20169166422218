import styled from "styled-components";

import { ReactComponent as HelpIconImage } from "../../assets/icons/helpIcon.svg";
import { ReactComponent as SearchIconImage } from "../../assets/icons/searchIcon.svg";

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  gap: ${({ theme }) => theme.spacing(10)};
`;

export const SearchInput = styled.input`
  width: 900px;
  height: 46px;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(10)}`};
  border: 1px solid ${({ theme }) => theme.palette.themeColors.borders.searchInput};
  border-radius: 60px;
  margin-right: ${({ theme }) => theme.spacing(4)};
  font-size: ${({ theme }) => theme.fontSize.base};
  z-index: 0;

  &::placeholder {
    color: ${({ theme }) => theme.palette.themeColors.fonts.placeholder};
    font-weight: ${({ theme }) => theme.fontWeight.neutral};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 1050px) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  position: relative;

  @media (max-width: 1050px) {
    width: 100%;
  }
`;

export const SearchIcon = styled(SearchIconImage)`
  width: 20px;
  height: 20px;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const HelpIcon = styled(HelpIconImage)`
  position: absolute;
  right: 20px;
  top: calc(50% - 13px);
  width: 25px;
  height: 25px;
  margin-left: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
`;
