import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useStellarContext } from "../../contexts/StellarContextProvider";

export const useGetSingleBlock = (blockNumber?: string) => {
  const { stellarServer } = useStellarContext();
  const { environment } = useParams<{ environment: string }>();
  const navigate = useNavigate();

  const [block, setBlock] = useState<ServerApi.LedgerRecord | undefined>();
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!blockNumber) return;

    setIsLoading(true);
    setBlock(undefined);

    (async () => {
      try {
        // @ts-ignore - we're fetching single ledger here. Typescript is wrongly typing it as a collection
        const fetchedBlock: ServerApi.LedgerRecord = await stellarServer
          ?.ledgers()
          .ledger(blockNumber)
          .call();

        setBlock(fetchedBlock);
      } catch (error: any) {
        if (error.response.title === "Bad Request") {
          return navigate(`/${environment}/not-found`);
        }
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();
  }, [blockNumber, environment, navigate, stellarServer, t]);

  return { block, isLoading, fetchingError };
};
