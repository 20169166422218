import styled from "styled-components";

import { ReactComponent as CopyIconImage } from "../../assets/icons/copyIcon.svg";

export const IconWrapper = styled.div<{ $bigSize?: boolean }>`
  position: relative;
  display: block;
  align-items: flex-end;
  width: ${({ $bigSize }) => ($bigSize ? "43px" : "25px")};
  height: ${({ $bigSize }) => ($bigSize ? "43px" : "25px")};
`;

export const CopyIcon = styled(CopyIconImage)<{ $bigSize?: boolean }>`
  width: ${({ $bigSize }) => ($bigSize ? "43px" : "25px")};
  height: ${({ $bigSize }) => ($bigSize ? "43px" : "25px")};
  cursor: pointer;
`;

export const CopiedMarker = styled.div<{ $onLeft?: boolean; $bigSize?: boolean }>`
  position: absolute;
  right: ${({ $onLeft, $bigSize }) => ($onLeft ? ($bigSize ? "50px" : "30px") : "-80px")};
  top: ${({ $bigSize }) => ($bigSize ? "4px" : "-8px")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.themeColors.backgrounds.copiedMarker};
  border: 1px solid ${({ theme }) => theme.palette.themeColors.borders.copiedMarkerBorder};
  border-radius: 6px;
  font-size: ${({ theme }) => theme.fontSize.m};
  padding: ${({ theme }) => theme.spacing(4)};
`;
