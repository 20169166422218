import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as PiLogo } from "../../../../assets/icons/logo.svg";

export const NAV_BAR_HEIGHT = 91;

export const NavBarContainer = styled.div`
  width: 100%;
  height: ${NAV_BAR_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(16)}`};
  background: ${({ theme }) => theme.palette.themeColors.backgrounds.navBar};

  @media (max-width: 450px) {
    padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(8)}`};
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const PiLogoIcon = styled(PiLogo)`
  width: 23px;
  height: 23px;
`;

export const PiText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.palette.themeColors.fonts.logoPiText};
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const BlockexplorerText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.palette.themeColors.fonts.secondary};
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(6)};
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(12)};

  @media (max-width: 450px) {
    gap: ${({ theme }) => theme.spacing(8)};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
`;
