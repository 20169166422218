import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import truncate from "lodash.truncate";
import React from "react";
import { useTranslation } from "react-i18next";

import NoDataToDisplay from "../../../../components/NoDataToDisplay/NoDataToDisplay";
import {
  FlexOneText,
  FlexOneWrapper,
  ListContainer,
  ListRow,
  MainContainer,
  TableTitles,
  Title,
} from "../../../../components/ReusableStyles";
import * as S from "./Data.styles";

type DataType = {
  [key: string]: string | boolean;
};

type SigningProps = {
  data: ServerApi.AccountRecord["data"];
};

const Data = (p: SigningProps) => {
  const { t } = useTranslation();

  const dataObject: DataType = Object(p.data);

  return (
    <MainContainer>
      <S.Header>
        <Title>{t("data")}</Title>
      </S.Header>
      {Object.keys(dataObject).length < 1 ? (
        <NoDataToDisplay />
      ) : (
        <>
          <TableTitles>
            <FlexOneText>{t("name")}</FlexOneText>
            <FlexOneText>{t("value")}</FlexOneText>
          </TableTitles>
          <ListContainer>
            {Object.keys(p.data).map(key => (
              <ListRow key={key}>
                <FlexOneWrapper>{key}</FlexOneWrapper>
                <FlexOneWrapper>
                  {typeof dataObject[key] === "boolean"
                    ? dataObject[key].toString()
                    : truncate(Buffer.from(String(dataObject[key])).toString("base64"))}
                </FlexOneWrapper>
              </ListRow>
            ))}
          </ListContainer>
        </>
      )}
    </MainContainer>
  );
};

export default Data;
