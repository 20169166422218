import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CustomButton from "../../../../CustomButton/CustomButton";
import { Backdrop } from "../../../../ReusableStyles";
import HeaderTabs from "../HeaderTabs/HeaderTabs";
import {
  ButtonsContainer,
  HamburgerMenuIcon,
  HamburgerWrapper,
  LeftContainer,
  MenuWrapper,
  RightContainer,
} from "./HamburgerMenu.styles";

type HamburgerMenuProps = {
  switchEnvironment: (environment?: string) => void;
};

const HamburgerMenu = (p: HamburgerMenuProps) => {
  const { environment } = useParams<{ environment: string }>();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {isMenuOpen && <Backdrop onClick={toggleMenu} />}
      <HamburgerWrapper onClick={toggleMenu} $isMenuOpen={isMenuOpen}>
        <HamburgerMenuIcon $isMenuOpen={isMenuOpen} />
        {isMenuOpen && (
          <MenuWrapper>
            <LeftContainer>
              <HeaderTabs />
            </LeftContainer>
            <RightContainer>
              <ButtonsContainer>
                <CustomButton
                  label={t("testnet").toUpperCase()}
                  onClick={() => p.switchEnvironment("testnet")}
                  active={environment === "testnet"}
                />
                <CustomButton
                  label={t("testnet2").toUpperCase()}
                  onClick={() => p.switchEnvironment("testnet2")}
                  active={environment === "testnet2"}
                />
                <CustomButton
                  label={t("mainnet").toUpperCase()}
                  onClick={() => p.switchEnvironment("mainnet")}
                  active={environment === "mainnet"}
                />
              </ButtonsContainer>
            </RightContainer>
          </MenuWrapper>
        )}
      </HamburgerWrapper>
    </>
  );
};

export default HamburgerMenu;
