import { MemoHash, MemoReturn } from "@stellar/stellar-sdk";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CopyIconButton from "../components/CopyIconButton/CopyIconButton";
import CustomInternalLink from "../components/CustomInternalLink/CustomInternalLink";
import JsonButton from "../components/JSONButton/JSONButton";
import LoaderWrapper from "../components/LoaderWrapper/LoaderWrapper";
import {
  ContentContainer,
  FlexStartRow,
  Info,
  Table,
  TableHeader,
  TableRow,
  TablesContainer,
  Title,
  TitleItemNumber,
  WrapperContainer,
} from "../components/ReusableStyles";
import { useStellarContext } from "../contexts/StellarContextProvider";
import { useGetSingleTransaction } from "../data/services/useGetSingleTransaction";
import { formatDateAndTime } from "../utils/formatDateAndTIme";
import { base64DecodeToHex } from "../utils/links";
import Operations from "./Operations";

const memoTypeToLabel = {
  id: "ID",
  hash: "Hash",
  none: "None",
  return: "Return",
  text: "Text",
};

const MAX_OPERATIONS_PER_PAGE = 10;

const Transaction = () => {
  const { environment, transactionId } = useParams<{ environment: string; transactionId: string }>();
  const { screenWidth } = useStellarContext();

  const { t } = useTranslation();

  const { transaction, isLoading, fetchingError } = useGetSingleTransaction(transactionId);

  if (!transaction || isLoading || fetchingError)
    return <LoaderWrapper loading={isLoading} fetchingError={fetchingError} />;

  return (
    <WrapperContainer>
      <ContentContainer>
        <TableHeader $alignItemsTop={screenWidth < 950}>
          {screenWidth > 950 && (
            <FlexStartRow>
              <Title>{t("transaction")}</Title>
              <TitleItemNumber>
                {transactionId} <CopyIconButton value={transactionId || ""} />
              </TitleItemNumber>
            </FlexStartRow>
          )}
          {screenWidth <= 950 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 100,
                gap: 10,
              }}
            >
              <Title>{t("transaction")}</Title>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: 100,
                  gap: 10,
                }}
              >
                <TitleItemNumber $displayInline>{transactionId}</TitleItemNumber>
                <CopyIconButton value={transactionId || ""} />
              </div>
            </div>
          )}
          <JsonButton type="transactions" recordId={transactionId} />
        </TableHeader>
        <TablesContainer>
          <Table>
            <TableRow>
              <Info>{t("time")}</Info>
              <Info>{formatDateAndTime(transaction?.created_at)}</Info>
            </TableRow>
            <TableRow>
              <Info>{t("fee")}</Info>
              <Info>
                {Number(transaction?.fee_charged) * 1e-7} {environment === "mainnet" ? "π" : "Test-π"}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{t("ledger")}</Info>
              <Info>
                <CustomInternalLink
                  to={`/${environment}/blocks/${transaction?.ledger_attr}`}
                  title={transaction?.ledger_attr.toString()}
                >
                  {transaction?.ledger_attr}
                </CustomInternalLink>
              </Info>
            </TableRow>
            <TableRow $last>
              <Info>
                {transaction?.memo_type && (
                  <>
                    {t("memo")}({memoTypeToLabel[transaction.memo_type]})
                  </>
                )}
              </Info>
              <Info>
                {(transaction?.memo_type === MemoHash || transaction?.memo_type === MemoReturn) &&
                transaction?.memo
                  ? base64DecodeToHex(transaction?.memo)
                  : transaction?.memo}
              </Info>
            </TableRow>
          </Table>
        </TablesContainer>
      </ContentContainer>
      {transaction?.operation_count && transaction?.operation_count > 0 && (
        <Operations
          maxOperationsPerPage={MAX_OPERATIONS_PER_PAGE}
          operationsNumber={transaction?.operation_count}
          recordId={transactionId}
          operationsFor="transaction"
          hideTransaction
          hideType
          showJson
        />
      )}
    </WrapperContainer>
  );
};

export default Transaction;
