// @ts-nocheck

import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import BigNumber from "bignumber.js";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";

import AccountLink from "../../AccountLink/AccountLink";
import Asset from "../../Asset";
import Offer from "../../Offer";

type EffectDetailsProps = {
  effect: ServerApi.EffectRecord;
  operation?: ServerApi.OperationRecord;
};

const EffectDetails = (p: EffectDetailsProps) => {
  const { t } = useTranslation();

  if (!p.operation) return <></>;

  if (p.effect.type === "account_created") {
    return (
      <span>
        {t("account")}
        {": "}
        <AccountLink variant="text" accountAddress={p.effect.account} />
        {"; "}
        {t("balance")}
        {": "}
        {new BigNumber(p.operation.starting_balance).toString()}
      </span>
    );
  }
  if (p.effect.type === "") {
    return (
      <span>
        {t("account")}
        {": "}
        {p.effect.account}
      </span>
    );
  }
  if (p.effect.type === "account_credited" || p.effect.type === "account_debited") {
    return (
      <span>
        {t("amount")}
        {": "}
        {new BigNumber(p.effect.amount).toString()}{" "}
        <Asset
          code={p.operation.asset_code}
          issuerAddress={p.operation.asset_issuer}
          type={p.operation.type}
        />
      </span>
    );
  }
  if (p.effect.type === "account_thresholds_updated") {
    return (
      <span>
        {t("threshold.low")}
        {": "}
        {p.operation.low_threshold}
        {" / "}
        {t("threshold.medium")}
        {": "}
        {p.operation.med_threshold}
        {" / "}
        {t("threshold.high")}
        {": "}
        {p.operation.high_threshold} /
      </span>
    );
  }
  if (p.effect.type === "account_home_domain_updated") {
    return (
      <span>
        {t("home.domain")} <a href={`https://${p.operation.home_domain}`}>{p.operation.home_domain}</a>
      </span>
    );
  }
  if (p.effect.type === "account_flags_updated") {
    return (
      <span>
        {t("flags")}
        {": "}
        {p.operation.set_flags}
      </span>
    );
  }
  if (p.effect.type === "sequence_bumped") {
    return <span>{p.operation.bump_to}</span>;
  }
  if (
    p.effect.type === "signer_created" ||
    p.effect.type === "signer_removed" ||
    p.effect.type === "signer_updated"
  ) {
    return (
      <span>
        {t("key.public")}
        {": "}
        {p.effect.public_key && <AccountLink variant="text" accountAddress={p.effect.public_key} />}
        {"; "}
        {t("weight")}
        {": "}
        {p.effect.weight}
      </span>
    );
  }
  if (
    p.effect.type === "trustline_created" ||
    p.effect.type === "trustline_updated" ||
    p.effect.type === "trustline_authorized" ||
    p.effect.type === "trustline_deauthorized"
  ) {
    return (
      <span>
        {t("asset")}
        {": "}
        <Asset
          code={p.operation.asset_code}
          type={p.operation.asset_type}
          issuerAddress={p.operation.asset_issuer ?? p.operation.trustor}
        />
        {p.operation.limit && (
          <>
            {"; "}
            {t("limit")}
            {": "}
            {p.operation.limit}
          </>
        )}
      </span>
    );
  }
  if (p.effect.type === "trustline_removed") {
    return (
      <span>
        <Asset
          code={p.operation.asset_code}
          type={p.operation.asset_type}
          issuerAddress={p.operation.asset_issuer}
        />
      </span>
    );
  }
  if (
    p.effect.type === "offer_created" ||
    p.effect.type === "offer_removed" ||
    p.effect.type === "offer_updated"
  ) {
    return <Offer operation={p.operation} />;
  }
  if (p.effect.type === "trade") {
    return (
      <span>
        {t("bought")}
        {": "}
        <span>
          {t("amount")}
          {": "}
          {new BigNumber(p.operation.amount).toString()}
        </span>
        <Asset
          code={p.operation.buying_asset_code}
          type={p.operation.buying_asset_type}
          issuerAddress={p.operation.buying_asset_issuer}
        />
        {"; "}
        {t("sold")}
        {": "}
        <span>
          {t("amount")}
          {": "}
          {new BigNumber(p.operation.price).toString()}
        </span>
        <Asset
          code={p.operation.selling_asset_code}
          type={p.operation.selling_asset_type}
          issuerAddress={p.operation.selling_asset_issuer}
        />
        {"; "}
        {p.operation.selling_asset_issuer && (
          <span>
            {t("seller")}
            {": "}
            <AccountLink variant="text" accountAddress={p.operation.selling_asset_issuer} />
          </span>
        )}
      </span>
    );
  }
  if (
    p.effect.type === "data_created" ||
    p.effect.type === "data_removed" ||
    p.effect.type === "data_updated"
  ) {
    return (
      <div>
        <div>
          {t("key")}
          {": "}
          <span title={p.operation.name}>{truncate(p.operation.name)}</span>
        </div>

        {p.effect.type !== "data_removed" && (
          <div>
            {t("value")}
            {": "}
            <span title={truncate(Buffer.from(p.operation.value).toString("base64"))}>
              {truncate(Buffer.from(p.operation.value).toString("base64"))}
            </span>
          </div>
        )}
      </div>
    );
  }

  return <span>{p.effect.type}</span>;
};

export default EffectDetails;
