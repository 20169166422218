import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.p<{ $additionalMarginTop?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  margin-bottom: ${({ theme }) => theme.spacing(15)};

  ${({ theme, $additionalMarginTop }) => $additionalMarginTop && `margin-top: ${theme.spacing(15)}`}
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(15)};
`;

export const SectionTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const SectionDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.neutral};
  padding-left: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(3)};
  text-align: center;
`;

export const FakeInput = styled.input.attrs({
  disabled: true,
})`
  text-align: center;
  width: 550px;
  max-width: 100%;
  height: 36px;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(8)}`};
  border: 1px solid ${({ theme }) => theme.palette.themeColors.borders.searchInput};
  border-radius: 60px;
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const OpenSearchImage = styled.img.attrs({
  src: "https://user-images.githubusercontent.com/1477978/33513399-8cf8ac52-d774-11e7-9585-ddc5467a5a2d.png",
  alt: "search by transaction hash",
})`
  margin-top: ${({ theme }) => theme.spacing(10)};
  width: 450px;
  max-width: 100%;
  height: 250px;
`;
