type ColorVariants = { [x: string]: ColorVariants | ColorCode };

export const colors = {
  purple: {
    juicy: "#a34ea7",
    dark: "#703D92",
    main: "#8B62DC",
    regular: "#A77AFF",
    light: "#C995F1",
    lighter: "#E7D7F3",
    lightest: "#F3EBF9",
  },
  orange: {
    dark: "#DC8605",
    main: "#FFA500",
    light: "#FBB44A",
    lighter: "#FCD89C",
    lightest: "#FFF1E0",
  },
  blue: {
    main: "#0000FF",
  },
  success: {
    main: "#A7D777",
    dark: "#6AA84F",
    regular: "#92C75D",
    lightest: "#F6FBF0",
  },
  error: {
    main: "#F15A5A",
    lighter: "#F5AAAA",
    lightest: "#FDEAEA",
  },
  grey: {
    50: "#F6F6F6",
    100: "#F3F3F3",
    150: "#EEEEEE",
    200: "#E9E9EB",
    250: "#e8e7e9",
    300: "#E0E0E0",
    350: "#D3D3D3",
    400: "#D6D0D9",
    450: "#B8B0BB",
    500: "#B0B0BF",
    550: "#96a2b4",
    600: "#88828B",
    650: "#534F55",
    700: "#3E3E40",
    750: "#2C2C2E",
    800: "#242424",
    850: "#1C1C1E",
  },

  gradients: {
    purpleToBlue: "linear-gradient(0.25turn, rgb(140, 53, 144), rgb(44, 73, 130))",
  },

  common: {
    white: "#FFF",
    black: "#1F1F1F",
  },
} satisfies ColorVariants;

export const lightThemeColors = {
  ...colors,

  themeColors: {
    backgrounds: {
      main: colors.grey["100"],
      contentBackground: colors.common.white,
      navBar: colors.gradients.purpleToBlue,
      languagesList: colors.gradients.purpleToBlue,
      buttonPrimaryActive: colors.orange.light,
      buttonSecondary: colors.purple.juicy,
      buttonSecondaryHover: colors.purple.dark,
      buttonSearch: colors.common.white,
      buttonSearchHover: colors.grey["50"],
      vieAllHover: colors.orange.lightest,
      buttonGrey: colors.grey["100"],
      spinnerBottom: colors.purple.light,
      spinnerTop: colors.purple.main,
      copiedMarker: colors.common.white,
      jsonButton: colors.grey["400"],
      jsonButtonHover: colors.grey["500"],
      hamburgerMenuFocus: colors.grey["400"],
      hamburgerMenuHover: colors.grey["100"],
    },

    fonts: {
      main: colors.common.black,
      secondary: colors.common.white,
      logoPiText: colors.orange.light,
      headerHover: colors.orange.main,
      customLink: colors.blue.main,
      customLinkHover: colors.orange.main,
      tableHeader: colors.grey["550"],
      placeholder: colors.grey["550"],
      viewAllButton: colors.orange.dark,
      changePageButton: colors.orange.main,
      changePageButtonHover: colors.purple.juicy,
      buttonSecondary: colors.common.white,
      buttonSearch: colors.grey["600"],
      secondaryTitle: colors.grey["600"],
      error: colors.error.main,
      jsonButton: colors.common.white,
      noDataInEnvironment: colors.purple.dark,
      hamburgerMenu: colors.common.white,
      hamburgerMenuFocus: colors.common.black,
    },

    borders: {
      buttonPrimary: colors.orange.light,
      buttonSearch: colors.grey["400"],
      buttonSearchHover: colors.grey["500"],
      tableTitleBorderBottom: colors.grey["250"],
      searchInput: colors.grey["400"],
      changePageButtonHover: colors.purple.juicy,
      copiedMarkerBorder: colors.grey["400"],
      hamburgerBorder: colors.common.white,
      hamburgerMenuFocus: colors.common.black,
      hamburgerMenuDivider: colors.grey["100"],
    }
  },
} satisfies ColorVariants;

