import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Effects from "../../../../components/Effects";
import Offers from "../../../../components/Offers";
import { ContentContainer } from "../../../../components/ReusableStyles";
import Trades from "../../../../components/Trades";
import Operations from "../../../Operations";
import Payments from "../../../Payments";
import Transactions from "../../../Transactions";
import Balances from "../Balances/Balances";
import Data from "../Data/Data";
import Flags from "../Flags/Flags";
import Signing from "../Signing/Signing";
import * as S from "./BottomPanel.styles";
import { TabButton } from "./BottomPanel.styles";

const MAX_RECORDS_PER_PAGE = 10;

const tabs = (t: TFunction) => [
  t("balances"),
  t("payments"),
  t("offers"),
  t("trades"),
  t("effects"),
  t("operations"),
  t("transactions"),
  t("signing"),
  t("flags"),
  t("data"),
];

type BottomPanelProps = {
  accountId: string;
  account: ServerApi.AccountRecord;
};

const BottomPanel = (p: BottomPanelProps) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const { t } = useTranslation();

  const changeTab = (index: number) => {
    setCurrentTabIndex(index);
  };

  return (
    <>
      <ContentContainer>
        <S.TabsContainer>
          {tabs(t).map((tab, index) => (
            <TabButton $active={index === currentTabIndex} onClick={() => changeTab(index)}>
              {tab}
            </TabButton>
          ))}
        </S.TabsContainer>
      </ContentContainer>
      {currentTabIndex === 0 && <Balances balances={p.account.balances} />}
      {currentTabIndex === 1 && (
        <Payments maxPaymentsPerPage={MAX_RECORDS_PER_PAGE} accountId={p.accountId} />
      )}
      {currentTabIndex === 2 && <Offers maxOffersPerPage={MAX_RECORDS_PER_PAGE} accountId={p.accountId} />}
      {currentTabIndex === 3 && <Trades maxTradesPerPage={MAX_RECORDS_PER_PAGE} accountId={p.accountId} />}
      {currentTabIndex === 4 && <Effects accountId={p.accountId} />}
      {currentTabIndex === 5 && (
        <Operations
          maxOperationsPerPage={MAX_RECORDS_PER_PAGE}
          recordId={p.accountId}
          operationsFor="account"
        />
      )}
      {currentTabIndex === 6 && (
        <Transactions
          maxTransactionsPerPage={MAX_RECORDS_PER_PAGE}
          recordId={p.accountId}
          transactionsFor="account"
          hideSource
        />
      )}
      {currentTabIndex === 7 && <Signing signers={p.account.signers} thresholds={p.account.thresholds} />}
      {currentTabIndex === 8 && <Flags flags={p.account.flags} />}
      {currentTabIndex === 9 && <Data data={p.account.data} />}
    </>
  );
};

export default BottomPanel;
