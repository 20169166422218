import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React from "react";
import { useTranslation } from "react-i18next";

import NoDataToDisplay from "../../../../components/NoDataToDisplay/NoDataToDisplay";
import {
  FlexOneText,
  FlexOneWrapper,
  ListContainer,
  ListRow,
  MainContainer,
  TableTitles,
  TextWrap,
  Title,
} from "../../../../components/ReusableStyles";
import * as S from "./Flags.styles";

type SigningProps = {
  flags: ServerApi.AccountRecord["flags"];
};

const Flags = (p: SigningProps) => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <S.Header>
        <Title>{t("flags")}</Title>
      </S.Header>
      {Object.keys(p.flags).length < 1 ? (
        <NoDataToDisplay />
      ) : (
        <>
          <TableTitles>
            <FlexOneText>{t("name")}</FlexOneText>
            <FlexOneText>{t("value")}</FlexOneText>
          </TableTitles>
          <ListContainer>
            {p.flags.auth_required !== undefined && (
              <ListRow>
                <FlexOneWrapper>
                  <TextWrap>{Object.keys(p.flags)[0]}</TextWrap>
                </FlexOneWrapper>
                <FlexOneWrapper>
                  <TextWrap>{String(p.flags.auth_required)}</TextWrap>
                </FlexOneWrapper>
              </ListRow>
            )}
            {p.flags.auth_revocable !== undefined && (
              <ListRow>
                <FlexOneWrapper>
                  <TextWrap>{Object.keys(p.flags)[1]}</TextWrap>
                </FlexOneWrapper>
                <FlexOneWrapper>
                  <TextWrap>{String(p.flags.auth_revocable)}</TextWrap>
                </FlexOneWrapper>
              </ListRow>
            )}
            {p.flags.auth_immutable !== undefined && (
              <ListRow>
                <FlexOneWrapper>
                  <TextWrap>{Object.keys(p.flags)[2]}</TextWrap>
                </FlexOneWrapper>
                <FlexOneWrapper>
                  <TextWrap>{String(p.flags.auth_immutable)}</TextWrap>
                </FlexOneWrapper>
              </ListRow>
            )}
            {p.flags.auth_clawback_enabled !== undefined && (
              <ListRow $isLast>
                <FlexOneWrapper>
                  <TextWrap>{Object.keys(p.flags)[3]}</TextWrap>
                </FlexOneWrapper>
                <FlexOneWrapper>
                  <TextWrap>{String(p.flags.auth_clawback_enabled)}</TextWrap>
                </FlexOneWrapper>
              </ListRow>
            )}
          </ListContainer>
        </>
      )}
    </MainContainer>
  );
};

export default Flags;
