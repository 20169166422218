import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import { useStellarContext } from "../../../../contexts/StellarContextProvider";
import { shortenString } from "../../../../utils/links";
import CustomInternalLink from "../../../CustomInternalLink/CustomInternalLink";
import JsonButton from "../../../JSONButton/JSONButton";
import { ListRow, TextWrap } from "../../../ReusableStyles";
import EffectDetails from "../EffectDetails";
import * as S from "./EffectRow.styles";

type EffectRowProps = {
  effect: ServerApi.EffectRecord;
  index: number;
  recordsLength: number;
};

const EffectRow = (p: EffectRowProps) => {
  const { environment } = useParams<{ environment: string }>();
  const { screenWidth } = useStellarContext();

  const [effectOperation, setEffectOperation] = useState<ServerApi.OperationRecord | undefined>();

  useEffect(() => {
    const getEffectOperation = async () => {
      const fetchedOperation = await p.effect.operation?.();
      setEffectOperation(fetchedOperation);
    };

    getEffectOperation();
  }, [p.effect]);

  return (
    <ListRow $isLast={p.index === p.recordsLength - 1}>
      <S.TypeWrapper>
        <TextWrap>{p.effect.type}</TextWrap>
      </S.TypeWrapper>
      <S.DetailsWrapper>
        <TextWrap>
          <EffectDetails effect={p.effect} operation={effectOperation} />
        </TextWrap>
      </S.DetailsWrapper>
      {screenWidth > 460 && (
        <S.TransactionWrapper>
          <CustomInternalLink
            to={`/${environment}/transactions/${effectOperation?.transaction_hash}`}
            title={effectOperation?.transaction_hash}
          >
            {shortenString(effectOperation?.transaction_hash || "", screenWidth < 600 ? 8 : 15)}
          </CustomInternalLink>
        </S.TransactionWrapper>
      )}
      <S.TimeWrapper>
        <ReactTimeAgo date={new Date(p.effect.created_at)} locale="en-US" timeStyle="round" />
      </S.TimeWrapper>
      <S.JsonWrapper>
        <JsonButton type="operations" recordId={effectOperation?.id} />
      </S.JsonWrapper>
    </ListRow>
  );
};

export default EffectRow;
