import styled from "styled-components";

import { TableHeader } from "../../../../components/ReusableStyles";

export const Header = styled(TableHeader)`
  border: unset;
`;

export const AssetText = styled.p`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const BalanceText = styled(AssetText)``;

export const LimitText = styled(AssetText)``;

export const AssetWrapper = styled.div`
  flex: 1;
  min-width: 60px;
`;

export const BlockWrapper = styled(AssetWrapper)`
  min-width: 60px;
`;

export const LimitWrapper = styled(AssetWrapper)``;
