import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStellarContext } from "../../contexts/StellarContextProvider";
import { Builder, Records } from "../../data/services/usePagination";
import { downloadRecordsAsCSV } from "../../utils/exportAsACsv";
import CustomButton from "../CustomButton/CustomButton";
import { Container } from "./ExportCSV.styles";

const RECORDS_LIMIT_PER_PAGE = 100;
const RECORDS_LIMIT = 20000;

type ExportCsvProps = {
  recordsType:
    | "transactions"
    | "payments"
    | "operations"
    | "paymentsForAccount"
    | "offersForAccount"
    | "tradesForAccount"
    | "effectsForAccount"
    | "operationsForAccount"
    | "transactionsForAccount"
    | "transactionsForBlocks"
    | "operationsForTransactions";
  recordId?: string;
};

const ExportCsv = (p: ExportCsvProps) => {
  const { stellarServer } = useStellarContext();

  const [server, setServer] = useState<Builder<Records> | undefined>();
  const [records, setRecords] = useState<Records[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchingFinished, setFetchingFinished] = useState(false);
  const [maxRecordsReached, setMaxRecordsReached] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    let pickedServer;
    if (p.recordsType === "operations") pickedServer = stellarServer?.operations();
    if (p.recordsType === "payments") pickedServer = stellarServer?.payments();
    if (p.recordsType === "transactions") pickedServer = stellarServer?.transactions();
    if (p.recordsType === "paymentsForAccount")
      pickedServer = stellarServer?.payments().forAccount(p.recordId || "");
    if (p.recordsType === "offersForAccount")
      pickedServer = stellarServer?.offers().forAccount(p.recordId || "");
    if (p.recordsType === "tradesForAccount")
      pickedServer = stellarServer?.trades().forAccount(p.recordId || "");
    if (p.recordsType === "effectsForAccount")
      pickedServer = stellarServer?.effects().forAccount(p.recordId || "");
    if (p.recordsType === "operationsForAccount")
      pickedServer = stellarServer?.operations().forAccount(p.recordId || "");
    if (p.recordsType === "transactionsForAccount")
      pickedServer = stellarServer?.transactions().forAccount(p.recordId || "");
    if (p.recordsType === "transactionsForBlocks")
      pickedServer = stellarServer?.transactions().forLedger(p.recordId || "");
    if (p.recordsType === "operationsForTransactions")
      pickedServer = stellarServer?.operations().forTransaction(p.recordId || "");

    setServer(pickedServer);
  }, [p.recordId, p.recordsType, stellarServer]);

  const fetchBaseRecords = async () => {
    const fetchedRecords = await server?.limit(RECORDS_LIMIT_PER_PAGE).order("desc").call();

    setRecords(fetchedRecords?.records || []);
    return fetchedRecords?.records || [];
  };

  const fetchNextPagesRecords = async (lastRecordPagingToken: string) => {
    if (!lastRecordPagingToken) {
      throw new Error("No paging_token found in the last record.");
    }

    const fetchedRecords = await server
      ?.limit(RECORDS_LIMIT_PER_PAGE)
      .order("desc")
      .cursor(lastRecordPagingToken)
      .call();

    setRecords([...records, ...(fetchedRecords?.records || [])]);
    return fetchedRecords?.records || [];
  };

  const fetchAndDownload = async () => {
    setIsFetching(true);

    let totalRecords = await fetchBaseRecords();

    if (totalRecords.length > 0) {
      while (totalRecords.length < RECORDS_LIMIT) {
        const lastRecord = totalRecords[totalRecords.length - 1];

        // If no paging_token is found, break the loop
        if (!lastRecord?.paging_token) {
          console.log("No more pages to fetch.");
          break;
        }

        const nextPageRecords = await fetchNextPagesRecords(lastRecord.paging_token);

        // If fewer than RECORDS_LIMIT_PER_PAGE are returned, stop fetching
        if (nextPageRecords.length < RECORDS_LIMIT_PER_PAGE) {
          // Append the new records to the current totalRecords
          totalRecords = [...totalRecords, ...nextPageRecords];
          setRecords(totalRecords); // Correctly update the state with all fetched records
          break;
        }

        totalRecords = [...totalRecords, ...nextPageRecords];
        setRecords(totalRecords);

        // If we've reached the RECORDS_LIMIT, stop fetching
        if (totalRecords.length >= RECORDS_LIMIT) {
          setMaxRecordsReached(true);
          totalRecords = totalRecords.slice(0, RECORDS_LIMIT); // Trim to exactly 20,000 records
          break;
        }
      }
    }

    setFetchingFinished(true);
    setIsFetching(false);

    // Download CSV when fetching is completed
    void downloadRecordsAsCSV(totalRecords);
  };

  return (
    <Container>
      {maxRecordsReached && `${t("csv-export.limit-exceeded", { count: RECORDS_LIMIT })}`}
      {fetchingFinished && !maxRecordsReached && `${t("csv-export.complete", { count: records.length })}`}
      {records.length > 0 &&
        !fetchingFinished &&
        !maxRecordsReached &&
        `${t("csv-export.fetching")} | ${t("csv-export.fetched", { count: records.length })}`}
      {!isFetching && !fetchingFinished && !maxRecordsReached && (
        <CustomButton onClick={fetchAndDownload} variant="viewAll" label={t("csv-export")} />
      )}
    </Container>
  );
};

export default ExportCsv;
