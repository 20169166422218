import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import CustomButton from "../../../../components/CustomButton/CustomButton";
import LoaderWrapper from "../../../../components/LoaderWrapper/LoaderWrapper";
import OperationsTable from "../../../../components/OperationsTable/OperationsTable";
import { Title, TitleRow } from "../../../../components/ReusableStyles";
import { useGetOperations } from "../../../../data/services/useGetOperations";
import { SectionContainer } from "../../Home.styles";

const OperationsPanel = () => {
  const { environment } = useParams<{ environment: string }>();
  const { operations, isLoading, fetchingError } = useGetOperations({});

  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToAllOperations = () => {
    navigate(`/${environment}/operations`);
  };

  return (
    <SectionContainer>
      <TitleRow>
        <Title>{t("latest.operations")}</Title>
        <CustomButton label={t("view.all")} variant="viewAll" onClick={goToAllOperations} />
      </TitleRow>
      <LoaderWrapper loading={isLoading} fetchingError={fetchingError}>
        <OperationsTable operations={operations} />
      </LoaderWrapper>
    </SectionContainer>
  );
};

export default OperationsPanel;
