import React, { ReactNode } from "react";

import { CustomLink } from "./CustomInternalLink.styles";

type CustomInternalLinkProps = {
  title?: string;
  to: string;
  children: ReactNode;
  monospace?: boolean;
};

const CustomInternalLink = (p: CustomInternalLinkProps) => {
  return (
    <span title={p.title}>
      <CustomLink to={p.to} $monospace={p.monospace}>
        {p.children}
      </CustomLink>
    </span>
  );
};

export default CustomInternalLink;
