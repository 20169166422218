import { MuxedAccount } from "@stellar/stellar-sdk";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { checkIfMuxedAddress } from "../../utils/links";
import { LinkContainer, LinkLabel } from "./AccountLink.styled";

export type LinkVariant = "button" | "text";

type AccountLinkProps = {
  variant: LinkVariant;
  accountAddress: string;
  first?: boolean;
  last?: boolean;
};

const AccountLink = (p: AccountLinkProps) => {
  const { environment } = useParams<{ environment: string }>();

  const [baseAddress, setBaseAddress] = useState("");
  const [muxedAddress, setMuxedAddress] = useState<string | null>(null);
  const [spanTitle, setSpanTitle] = useState("");

  useEffect(() => {
    const isMuxedAddress = checkIfMuxedAddress(p.accountAddress);

    if (isMuxedAddress) {
      const muxedAccount = MuxedAccount.fromAddress(p.accountAddress, "1");
      setBaseAddress(muxedAccount.baseAccount().accountId());
      setMuxedAddress(muxedAccount.accountId());
      setSpanTitle(`Muxed address: ${muxedAccount.accountId()}`);
    } else {
      setBaseAddress(p.accountAddress);
      setSpanTitle(`Base address: ${p.accountAddress}`);
    }
  }, [p.accountAddress]);

  if (!baseAddress && !muxedAddress) return <></>;

  return (
    <LinkContainer title={spanTitle} $variant={p.variant} $first={p.first} $last={p.last}>
      {muxedAddress ? (
        <>
          <LinkLabel
            to={`/${environment}/accounts/${baseAddress}`}
            state={{ muxedAddress: muxedAddress }}
            $variant={p.variant}
          >
            {muxedAddress.slice(0, 4)}...
            {muxedAddress.slice(muxedAddress.length - 4, muxedAddress.length)}
          </LinkLabel>
          {p.variant === "text" && (
            <>
              (a sub-address of{" "}
              <LinkLabel to={`/${environment}/accounts/${baseAddress}`} $variant={p.variant}>
                {baseAddress?.slice(0, 4)}
              </LinkLabel>
              )
            </>
          )}
        </>
      ) : (
        <LinkLabel to={`/${environment}/accounts/${baseAddress}`} $variant={p.variant}>
          {baseAddress?.slice(0, 4)}
        </LinkLabel>
      )}
    </LinkContainer>
  );
};

export default AccountLink;
