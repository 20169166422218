import styled from "styled-components";

export const AccountText = styled.p<{ $expanded?: boolean }>`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  min-width: 70px;
`;

export const BoughtText = styled(AccountText)``;

export const TimeText = styled(AccountText)``;

export const AccountWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const BoughtWrapper = styled(AccountWrapper)``;

export const TimeWrapper = styled(AccountWrapper)``;
