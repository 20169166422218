import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import BlocksTable from "../../../components/BlocksTable/BlocksTable";
import CustomButton from "../../../components/CustomButton/CustomButton";
import LoaderWrapper from "../../../components/LoaderWrapper/LoaderWrapper";
import { Title, TitleRow } from "../../../components/ReusableStyles";
import { useGetBlocks } from "../../../data/services/useGetBlocks";
import { SectionContainer } from "../Home.styles";

const BlocksPanel = () => {
  const { environment } = useParams<{ environment: string }>();
  const { blocks, isLoading, fetchingError } = useGetBlocks();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToAllBlocks = () => {
    navigate(`/${environment}/blocks/`);
  };

  return (
    <SectionContainer>
      <TitleRow>
        <Title>{t("latest.ledgers")}</Title>
        <CustomButton label={t("view.all")} variant="viewAll" onClick={goToAllBlocks} />
      </TitleRow>
      <LoaderWrapper loading={isLoading} fetchingError={fetchingError}>
        <BlocksTable blocks={blocks} />
      </LoaderWrapper>
    </SectionContainer>
  );
};

export default BlocksPanel;
