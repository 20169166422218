export const formatDateAndTime = (dateString?: string) => {
  if (!dateString) return;

  const date = new Date(dateString);
  const month = date.getMonth();
  const day = date.getDay();
  const year = date.getFullYear();
  const hours = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${month}/${day}/${year} ${hours}:${minutes}`;
};
