import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LoaderWrapper from "../../../../components/LoaderWrapper/LoaderWrapper";
import {
  FlexStartRow,
  Info,
  Table,
  TableHeader,
  TableRow,
  TablesContainer,
  Title,
} from "../../../../components/ReusableStyles";
import { getMainnetMetrics, MainnetMetricsType } from "../../../../data/services/getMainnetMetrics";
import * as S from "./MainnetMetrics.styles";

const MainnetMetrics = () => {
  const [metrics, setMetrics] = useState<MainnetMetricsType | null>();
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const fetchedMetrics = await getMainnetMetrics();

        setMetrics(fetchedMetrics);
      } catch (error: any) {
        setFetchingError({ title: t("fetching.error"), error });
      }
    })();

    setIsLoading(false);
  }, [t]);

  return (
    <S.Container>
      <TableHeader>
        <FlexStartRow>
          <Title>
            {t("mainnet")} {t("metrics")}
          </Title>
        </FlexStartRow>
      </TableHeader>
      <LoaderWrapper loading={!metrics || isLoading} containerHeight={23} fetchingError={fetchingError}>
        <TablesContainer>
          <Table>
            <TableRow>
              <Info>{t("total.migrated.mining.rewards")}</Info>
              <Info $textOnRight>
                {metrics?.total_circulating_supply}{" "}
                {metrics?.total_circulating_supply && metrics?.total_circulating_supply !== "n/a" && "π"}
              </Info>
            </TableRow>
            <TableRow $last>
              <Info>{t("currently.locked.mining.rewards")}</Info>
              <Info $textOnRight>
                {metrics?.total_locked} {metrics?.total_locked && metrics?.total_locked !== "n/a" && "π"}
              </Info>
            </TableRow>
          </Table>
        </TablesContainer>
      </LoaderWrapper>
    </S.Container>
  );
};

export default MainnetMetrics;
