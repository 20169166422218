import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import { useStellarContext } from "../../contexts/StellarContextProvider";
import CustomInternalLink from "../CustomInternalLink/CustomInternalLink";
import { ListContainer, ListRow, TableTitles } from "../ReusableStyles";
import * as S from "./BlocksTable.styles";

type BlocksTableProps = {
  blocks?: ServerApi.CollectionPage<ServerApi.LedgerRecord>;
  expanded?: boolean;
};

const BlocksTable = (p: BlocksTableProps) => {
  const { environment } = useParams<{ environment: string }>();

  const { screenWidth } = useStellarContext();

  const { t } = useTranslation();

  return (
    <>
      <TableTitles>
        <S.HashMark>#</S.HashMark>
        <S.BlockText>{screenWidth < 400 ? t("tsx") : t("transactions")}</S.BlockText>
        <S.TimeText>{t("time")}</S.TimeText>
      </TableTitles>
      <ListContainer>
        {p.blocks?.records.map((block, index) => (
          <ListRow $isLast={index === (p.blocks?.records.length || 0) - 1} key={block.id}>
            <S.HashWrapper>
              <CustomInternalLink
                to={`/${environment}/blocks/${block.sequence}`}
                title={block.sequence.toString()}
              >
                {block.sequence}
              </CustomInternalLink>
            </S.HashWrapper>
            <S.BlockWrapper>
              {block.successful_transaction_count > 0 ? (
                <CustomInternalLink
                  to={`/${environment}/blocks/${block.sequence}`}
                  title={`${block.sequence}`}
                >
                  {block.successful_transaction_count} {p.expanded && t("successful")}
                </CustomInternalLink>
              ) : (
                <>
                  {block.successful_transaction_count} {p.expanded && t("successful")}
                </>
              )}{" "}
              / {block.failed_transaction_count} {p.expanded && t("failed")}
            </S.BlockWrapper>
            <S.TimeWrapper>
              <CustomInternalLink
                to={`/${environment}/blocks/${block.sequence}`}
                title={block.sequence.toString()}
              >
                <ReactTimeAgo date={new Date(block.closed_at)} locale="en-US" timeStyle="round" />
              </CustomInternalLink>
            </S.TimeWrapper>
          </ListRow>
        ))}
      </ListContainer>
    </>
  );
};

export default BlocksTable;
