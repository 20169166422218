import styled from "styled-components";

import errorImage from "../../assets/images/redWarningImage.png";

export const MessageContainer = styled.div<{ $height?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ $height }) => `${$height ?? 300}px`};
`;

export const ErrorImage = styled.img.attrs({
  src: errorImage,
})`
  width: 100px;
  height: 100px;
  margin-bottom: ${({ theme }) => theme.spacing(20)};
`;

export const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
`;
