import { Federation, MuxedAccount } from "@stellar/stellar-sdk";
import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useStellarContext } from "../../contexts/StellarContextProvider";
import { checkIfMuxedAddress, isFederatedAddress, isPublicKey } from "../../utils/links";

export type AddressType = "public" | "federated" | "muxed";

const checkAddressType = async (
  accountId: string,
  setAddressType: Dispatch<SetStateAction<AddressType | undefined>>
) => {
  if (isPublicKey(accountId)) {
    setAddressType("public");
    return accountId;
  }
  if (isFederatedAddress(accountId)) {
    const [name, domain] = accountId.split("*");
    const federationServer = await Federation.Server.createForDomain(domain);
    const resolvedAddress = await federationServer.resolveAddress(name);
    setAddressType("federated");
    return resolvedAddress.account_id;
  }
  if (checkIfMuxedAddress(accountId)) {
    const muxedAccount = MuxedAccount.fromAddress(accountId, "1");
    setAddressType("muxed");
    return muxedAccount.accountId();
  }

  return accountId;
};

export const useGetAccount = (accountId?: string) => {
  const { stellarServer } = useStellarContext();
  const { environment } = useParams<{ environment: string }>();
  const navigate = useNavigate();

  const [account, setAccount] = useState<ServerApi.AccountRecord | undefined>();
  const [addressType, setAddressType] = useState<AddressType>();
  const [finalAccountId, setFinalAccountId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!accountId) return;

    setIsLoading(true);
    setAccount(undefined);

    (async () => {
      try {
        const outputAccountId = await checkAddressType(accountId, setAddressType);

        setFinalAccountId(outputAccountId);
        // @ts-ignore - we're fetching single account here. Typescript is wrongly typing it as a collection
        const fetchedAccount: ServerApi.AccountRecord = await stellarServer
          ?.accounts()
          .accountId(outputAccountId)
          .call();

        setAccount(fetchedAccount);
      } catch (error: any) {
        if (error.response.title === "Bad Request") {
          return navigate(`/${environment}/not-found`);
        }
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();
  }, [accountId, environment, navigate, stellarServer, t]);

  return { account, addressType, finalAccountId, isLoading, fetchingError };
};
