import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { CallBuilder } from "@stellar/stellar-sdk/lib/horizon/call_builder";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { PaymentsCombined } from "./useGetPayments";

export type Records =
  | ServerApi.OperationRecord
  | ServerApi.LedgerRecord
  | ServerApi.TransactionRecord
  | ServerApi.EffectRecord
  | PaymentsCombined
  | ServerApi.OfferRecord
  | ServerApi.TradeRecord;

export type Collection<T extends Records> = ServerApi.CollectionPage<T>;

export type Builder<T extends Records> = CallBuilder<Collection<T>>;

export const usePagination = <T extends Records>(
  data: Collection<T> | undefined,
  setData: Dispatch<SetStateAction<Collection<T> | undefined>>,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  setFetchingError: Dispatch<SetStateAction<{ title: string; error: any } | null>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  server?: Builder<T>,
  maxRecordsPerPage?: number
) => {
  const { t } = useTranslation();

  const fetchNextPage = async () => {
    if (!data || !server) return;

    setIsLoading(true);

    const nextPage = await server
      .limit(maxRecordsPerPage ?? 10)
      .order("desc")
      .cursor(data.records[(maxRecordsPerPage ?? 10) - 1].paging_token)
      .call();

    if (nextPage && nextPage.records.length > 0) {
      setData(nextPage);
      setPage(page + 1);
    } else {
      setFetchingError({ title: t("last.page.reached"), error: null });
    }

    setIsLoading(false);
  };

  const fetchPreviousPage = async () => {
    if (!data || data.records.length < 1 || !server) return;

    setIsLoading(true);

    const prevPage = await server
      .limit(maxRecordsPerPage ?? 10)
      .order("asc")
      .cursor(data.records[0].paging_token)
      .call();

    if (prevPage) {
      const reversedRecords = prevPage.records.reverse();

      setData({ ...prevPage, records: reversedRecords });
      setFetchingError(null);
      setPage(page - 1);
    }

    setIsLoading(false);
  };

  return { fetchNextPage, fetchPreviousPage };
};
