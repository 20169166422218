import styled from "styled-components";

import { ReactComponent as HamburgerMenu } from "../../../../../assets/icons/hamburgerMenu.svg";
import { NAV_BAR_HEIGHT } from "../Header.styles";

type HamburgerProps = {
  $isMenuOpen: boolean;
};

export const HamburgerWrapper = styled.div<HamburgerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0.7px solid
    ${({ theme, $isMenuOpen }) =>
      $isMenuOpen
        ? theme.palette.themeColors.borders.hamburgerMenuFocus
        : theme.palette.themeColors.borders.hamburgerBorder};
  padding: ${({ theme }) =>
    `${theme.spacing(3)} ${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(3)}`};

  ${({ theme, $isMenuOpen }) =>
    $isMenuOpen && `background-color: ${theme.palette.themeColors.backgrounds.hamburgerMenuFocus}`}
`;

export const HamburgerMenuIcon = styled(HamburgerMenu)<HamburgerProps>`
  width: 30px;
  height: 30px;
  color: ${({ theme, $isMenuOpen }) =>
    $isMenuOpen
      ? theme.palette.themeColors.fonts.hamburgerMenuFocus
      : theme.palette.themeColors.fonts.hamburgerMenu};
`;

export const MenuWrapper = styled.div`
  position: absolute;
  left: 0;
  top: ${NAV_BAR_HEIGHT - 1}px;
  display: flex;
  width: 100vw;
  background: ${({ theme }) => theme.palette.themeColors.backgrounds.navBar};
  padding: ${({ theme }) => `0 ${theme.spacing(16)} ${theme.spacing(12)} ${theme.spacing(16)}`};
  z-index: 101;
`;

export const LeftContainer = styled.div`
  width: 100%;
  border-top: 0.5px solid ${({ theme }) => theme.palette.themeColors.borders.hamburgerMenuDivider};
  padding-top: ${({ theme }) => theme.spacing(8)};
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  border-top: 0.5px solid ${({ theme }) => theme.palette.themeColors.borders.hamburgerMenuDivider};
  padding-top: ${({ theme }) => theme.spacing(12)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
