import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React from "react";
import { useTranslation } from "react-i18next";

import Asset from "../Asset";
import { ListContainer, ListRow, TableTitles } from "../ReusableStyles";
import * as S from "./OffersTable.styles";

type OffersTableProps = {
  offers?: ServerApi.CollectionPage<ServerApi.OfferRecord>;
};

const OffersTable = (p: OffersTableProps) => {
  const { t } = useTranslation();

  if (!p.offers || p.offers.records.length < 1) return <></>;

  return (
    <>
      <TableTitles>
        <S.SellText>{t("sell")}</S.SellText>
        <S.AmountText>{t("buy")}</S.AmountText>
        <S.BuyText>{t("amount")}</S.BuyText>
        <S.PriceText>{t("price")}</S.PriceText>
      </TableTitles>
      <ListContainer>
        {p.offers?.records.map((offer, index) => (
          <ListRow $isLast={index === (p.offers?.records.length || 0) - 1} key={offer.id}>
            <S.SellWrapper>
              <Asset
                type={offer.selling.asset_type}
                code={offer.selling.asset_code}
                issuerAddress={offer.selling.asset_issuer}
              />
            </S.SellWrapper>
            <S.BuyWrapper>
              <Asset
                type={offer.buying.asset_type}
                code={offer.buying.asset_code}
                issuerAddress={offer.buying.asset_issuer}
              />
            </S.BuyWrapper>
            <S.AmountWrapper>{offer.amount}</S.AmountWrapper>
            <S.PriceWrapper>{offer.price}</S.PriceWrapper>
          </ListRow>
        ))}
      </ListContainer>
    </>
  );
};

export default OffersTable;
