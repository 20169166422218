import React from "react";
import { useTranslation } from "react-i18next";

import ExportCsv from "../components/ExportCSV/ExportCSV";
import LoaderWrapper from "../components/LoaderWrapper/LoaderWrapper";
import PagePagination from "../components/PagePagination";
import { MainContainer, TableHeader, Title } from "../components/ReusableStyles";
import TransactionsTable from "../components/TransactionsTable/TransactionsTable";
import { TransactionsFor, useGetTransactions } from "../data/services/useGetTransactions";

const DEFAULT_MAX_TRANSACTIONS = 20;

type TransactionsProps = {
  maxTransactionsPerPage?: number;
  hideBlocks?: boolean;
  hideSource?: boolean;
  transactionsNumber?: number;
  recordId?: string;
  transactionsFor?: TransactionsFor;
};

const Transactions = (p: TransactionsProps) => {
  const {
    transactions,
    setTransactions,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  } = useGetTransactions({
    transactionsLimit: p.maxTransactionsPerPage ?? DEFAULT_MAX_TRANSACTIONS,
    recordId: p.recordId,
    transactionsFor: p.transactionsFor,
  });

  const { t } = useTranslation();

  return (
    <MainContainer>
      <TableHeader>
        <Title>
          {p.transactionsNumber ? `${t("transactions")} (${p.transactionsNumber})` : t("transactions")}
        </Title>
      </TableHeader>
      <LoaderWrapper
        loading={isLoading}
        fetchingError={fetchingError}
        collectionEmpty={transactions && transactions.records.length < 1}
      >
        <PagePagination
          maxRecordsPerPage={p.maxTransactionsPerPage ?? DEFAULT_MAX_TRANSACTIONS}
          data={transactions}
          setData={setTransactions}
          page={page}
          setPage={setPage}
          fetchingError={fetchingError}
          setFetchingError={setFetchingError}
          server={server}
          setIsLoading={setIsLoading}
        >
          <TransactionsTable
            transactions={transactions}
            expanded
            hideBlocks={p.hideBlocks}
            hideSource={p.hideSource}
          />
        </PagePagination>
      </LoaderWrapper>
      {transactions && transactions.records.length > 0 && (
        <ExportCsv
          recordsType={
            p.transactionsFor === "account"
              ? "transactionsForAccount"
              : p.transactionsFor === "block"
              ? "transactionsForBlocks"
              : "transactions"
          }
          recordId={p.recordId}
        />
      )}
    </MainContainer>
  );
};

export default Transactions;
