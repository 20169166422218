import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { Builder, Collection, Records, usePagination } from "../data/services/usePagination";
import CustomButton from "./CustomButton/CustomButton";
import { ChangePageButtonsContainer, ErrorMessage, ErrorWrapper } from "./ReusableStyles";

type PagePaginationProps<T extends Records> = {
  maxRecordsPerPage: number;
  data?: Collection<T>;
  setData: Dispatch<SetStateAction<Collection<T> | undefined>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  fetchingError: { title: string; error: any } | null;
  setFetchingError: Dispatch<SetStateAction<{ title: string; error: any } | null>>;
  server?: Builder<T>;
  children: ReactNode;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const PagePagination = <T extends Records>(p: PagePaginationProps<T>) => {
  const { fetchPreviousPage, fetchNextPage } = usePagination(
    p.data,
    p.setData,
    p.page,
    p.setPage,
    p.setFetchingError,
    p.setIsLoading,
    p.server,
    p.maxRecordsPerPage
  );

  const { t } = useTranslation();

  return (
    <>
      <ChangePageButtonsContainer $page={p.page}>
        {p.page > 1 && (
          <CustomButton variant="viewAll" label={t("paging.prev")} onClick={fetchPreviousPage} />
        )}
        {p.data?.records.length === p.maxRecordsPerPage &&
          !(p.fetchingError?.title === t("last.page.reached")) && (
            <CustomButton variant="viewAll" label={t("paging.next")} onClick={fetchNextPage} />
          )}
      </ChangePageButtonsContainer>
      <ErrorWrapper>
        {p.fetchingError?.title === t("last.page.reached") && (
          <ErrorMessage>{t("last.page.reached")}</ErrorMessage>
        )}
      </ErrorWrapper>
      {p.children}
    </>
  );
};

export default PagePagination;
