import isString from "lodash.isstring";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useStellarContext } from "../../contexts/StellarContextProvider";
import { shortenString } from "../../utils/links";
import CustomButton from "../CustomButton/CustomButton";
import SpringModal from "../CustomModal/CustomModal";
import SearchInfoModalContent from "./components/SearchInfoModalContent/SearchInfoModalContent";
import { HelpIcon, InputWrapper, SearchBarContainer, SearchIcon, SearchInput } from "./SearchBar.styles";
import { transformSearchPhraseToPath } from "./utils";

const SearchBar = () => {
  const { environment } = useParams<{ environment: string }>();

  const { screenWidth } = useStellarContext();

  const [searchPhrase, setSearchPhrase] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };

  const onSearch = () => {
    const link = transformSearchPhraseToPath(searchPhrase, environment);

    if (!isString(link) || link.trim() === "") return null;

    setSearchPhrase("");

    navigate(link);
  };

  const searchOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const openHelpModal = () => {
    setIsModalOpen(true);
  };

  const closeHelpModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <SearchBarContainer>
        <InputWrapper>
          <SearchInput
            value={searchPhrase}
            onChange={onChange}
            placeholder={
              screenWidth < 480 ? shortenString(t("search.placeHolder"), 28) : t("search.placeHolder")
            }
            onKeyDown={searchOnKeyPress}
          />
          <HelpIcon onClick={openHelpModal} />
        </InputWrapper>
        <CustomButton icon={<SearchIcon />} variant="search" onClick={onSearch} />
      </SearchBarContainer>
      <SpringModal isOpen={isModalOpen} handleClose={closeHelpModal}>
        <SearchInfoModalContent />
      </SpringModal>
    </>
  );
};

export default SearchBar;
