import React, { ReactNode } from "react";
import { useSpring } from "react-spring";

import * as S from "./CustomModal.styles";

type ModalProps = {
  handleClose: () => void;
  children: ReactNode;
  isOpen: boolean;
};

const SpringModal = ({ handleClose, children, isOpen }: ModalProps) => {
  const backdropAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
  });

  const modalAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translate(-50%, -50%)" : "translate(-50%, -150%)",
  });

  if (!isOpen) return null;

  return (
    <>
      <S.ModalBackdrop style={backdropAnimation} onClick={handleClose} />
      <S.StyledModal style={modalAnimation}>
        <S.CloseIcon onClick={handleClose} />
        {children}
      </S.StyledModal>
    </>
  );
};

export default SpringModal;
