import styled from "styled-components";

export const SellText = styled.p<{ $expanded?: boolean }>`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const AmountText = styled(SellText)``;

export const BuyText = styled(SellText)``;

export const PriceText = styled(SellText)``;

export const SellWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const BuyWrapper = styled(SellWrapper)``;

export const AmountWrapper = styled(SellWrapper)``;

export const PriceWrapper = styled(SellWrapper)``;
