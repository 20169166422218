import { Link } from "react-router-dom";
import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(2.5)};

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Tab = styled(Link)<{ $active?: boolean }>`
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.palette.themeColors.fonts.secondary};
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(8)}`};

  &:hover {
    color: ${({ theme }) => theme.palette.themeColors.fonts.headerHover};
  }

  ${({ theme, $active }) => $active && `color: ${theme.palette.themeColors.fonts.headerHover}`};

  @media (max-width: 1000px) {
    padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(8)} ${theme.spacing(4)} 0`};
  }
`;
