import styled from "styled-components";

export const WrapperContainer = styled.div`
  width: 100%;

  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ColumnsPositioner = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(15)};
  margin-top: ${({ theme }) => theme.spacing(10)};

  @media (max-width: 1050px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(10)};
`;

export const RightColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(7)};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.themeColors.backgrounds.contentBackground};
  padding: ${({ theme }) => `${theme.spacing(10)} ${theme.spacing(7.5)}`};
`;
