import { resources } from "../../translations/resources";

export const languagesList: { code: keyof typeof resources; displayedName: string }[] = [
  { code: "en", displayedName: "English" },
  { code: "ar", displayedName: "عربي" },
  { code: "bn", displayedName: "বাংলা" },
  { code: "ca", displayedName: "Català" },
  { code: "es", displayedName: "Española" },
  { code: "fr", displayedName: "Français" },
  { code: "hau", displayedName: "Hausa" },
  { code: "hi", displayedName: "हिन्दी" },
  { code: "id", displayedName: "Bahasa Indonesia" },
  { code: "it", displayedName: "Italiana" },
  { code: "ja", displayedName: "日本語" },
  { code: "ne", displayedName: "नेपाली" },
  { code: "pt", displayedName: "Português" },
  { code: "ru", displayedName: "Pусский" },
  { code: "ur", displayedName: "اردو" },
  { code: "vi", displayedName: "Tiếng Việt" },
  { code: "zh-Hans", displayedName: "中文 (简体)" },
  { code: "zh-Hant", displayedName: "中文 (繁體)" },
];
