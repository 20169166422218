import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { CopiedMarker, CopyIcon, IconWrapper } from "./CopyIconButton.styles";

type CopyIconProps = {
  value: string;
  copiedInfoOnLeft?: boolean;
  bigSize?: boolean;
};

const CopyIconButton = (p: CopyIconProps) => {
  const [isCopyFinished, setIsCopyFinished] = useState(false);

  const { t } = useTranslation();

  const showCopiedMarker = () => {
    setIsCopyFinished(true);

    setTimeout(() => {
      setIsCopyFinished(false);
    }, 1000);
  };

  return (
    <IconWrapper $bigSize={p.bigSize}>
      <CopyToClipboard text={p.value} onCopy={showCopiedMarker}>
        <CopyIcon $bigSize={p.bigSize} />
      </CopyToClipboard>
      {isCopyFinished && (
        <CopiedMarker $onLeft={p.copiedInfoOnLeft} $bigSize={p.bigSize}>
          {t("copied")}
        </CopiedMarker>
      )}
    </IconWrapper>
  );
};

export default CopyIconButton;
