import { Link } from "react-router-dom";
import styled from "styled-components";

export const CustomLink = styled(Link)<{ $monospace?: boolean }>`
  display: inline;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.themeColors.fonts.customLink};
  ${({ $monospace }) => $monospace && "font-family: monospace"};

  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    color: ${({ theme }) => theme.palette.themeColors.fonts.viewAllButton};
  }
`;
