import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useStellarContext } from "../../contexts/StellarContextProvider";

export const useGetSingleTransaction = (transactionId?: string) => {
  const { stellarServer } = useStellarContext();
  const { environment } = useParams<{ environment: string }>();
  const navigate = useNavigate();

  const [transaction, setTransaction] = useState<ServerApi.TransactionRecord | undefined>();
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!transactionId) return;

    setIsLoading(true);
    setFetchingError(null);
    setTransaction(undefined);

    (async () => {
      try {
        // @ts-ignore - we're fetching single transaction here. Typescript is wrongly typing it as a collection
        const fetchedTransaction: ServerApi.TransactionRecord = await stellarServer
          ?.transactions()
          .transaction(transactionId || "")
          .call();

        setTransaction(fetchedTransaction);
      } catch (error: any) {
        if (error.response.title === "Bad Request") {
          return navigate(`/${environment}/not-found`);
        }
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();
  }, [environment, transactionId, stellarServer, t, navigate]);

  return { transaction, isLoading, fetchingError };
};
