import styled from "styled-components";

import { TableHeader } from "../../../../components/ReusableStyles";

export const Header = styled(TableHeader)`
  border: unset;
`;

export const SmallHeader = styled(TableHeader)`
  border: unset;
  margin-top: ${({ theme }) => theme.spacing(25)};
  padding-bottom: ${({ theme }) => theme.spacing(5)};
`;
