import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { PaymentCallBuilder } from "@stellar/stellar-sdk/lib/horizon/payment_call_builder";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStellarContext } from "../../contexts/StellarContextProvider";

export type PaymentsCombined =
  | ServerApi.PaymentOperationRecord
  | ServerApi.CreateAccountOperationRecord
  | ServerApi.AccountMergeOperationRecord
  | ServerApi.PathPaymentOperationRecord
  | ServerApi.PathPaymentStrictSendOperationRecord
  | ServerApi.InvokeHostFunctionOperationRecord;

export const useGetPayments = (paymentsLimit?: number, accountId?: string) => {
  const { stellarServer } = useStellarContext();

  const [server, setServer] = useState<PaymentCallBuilder | undefined>();
  const [payments, setPayments] = useState<ServerApi.CollectionPage<PaymentsCombined> | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    const baseServer = stellarServer?.payments();
    const accountPaymentsServer = stellarServer?.payments().forAccount(accountId || "");

    setServer(accountId ? accountPaymentsServer : baseServer);
  }, [accountId, stellarServer]);

  useEffect(() => {
    setIsLoading(true);
    setPayments(undefined);
    setFetchingError(null);

    (async () => {
      try {
        const fetchedPayments = await server
          ?.limit(paymentsLimit ?? 10)
          .order("desc")
          .call();

        setPayments(fetchedPayments);
      } catch (error: any) {
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();

    setPage(1);
  }, [paymentsLimit, server, t]);

  return {
    payments,
    setPayments,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  };
};
