import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import BigNumber from "bignumber.js";
import React from "react";
import { Trans } from "react-i18next";

import Asset from "./Asset";

const choseOfferType = (offerId: string | number, amount: string) => {
  if (offerId === 0) return "sell";
  else if (parseFloat(amount) === 0) return "remove";
  else return "update";
};

type OfferProps = {
  operation: ServerApi.OperationRecord;
};

const Offer = (p: OfferProps) => {
  if (p.operation.type !== "create_passive_sell_offer" && p.operation.type !== "manage_sell_offer")
    return <></>;

  const translationKey = `operation.offer.${choseOfferType(p.operation.offer_id, p.operation.amount)}`;

  return (
    <Trans
      i18nKey={translationKey}
      values={{
        amount: new BigNumber(p.operation.amount).toString(),
        price: new BigNumber(p.operation.price).toString(),
      }}
      components={{
        buyingAsset: (
          <Asset
            code={p.operation.buying_asset_code}
            issuerAddress={p.operation.buying_asset_issuer}
            type={p.operation.buying_asset_type}
          />
        ),
        sellingAsset: (
          <Asset
            code={p.operation.selling_asset_code}
            issuerAddress={p.operation.selling_asset_issuer}
            type={p.operation.selling_asset_type}
          />
        ),
      }}
    />
  );
};

export default Offer;
