import { HorizonApi } from "@stellar/stellar-sdk/lib/horizon/horizon_api";
import BigNumber from "bignumber.js";
import React from "react";
import { useTranslation } from "react-i18next";

import Asset from "../../../../components/Asset";
import NoDataToDisplay from "../../../../components/NoDataToDisplay/NoDataToDisplay";
import {
  ListContainer,
  ListRow,
  MainContainer,
  TableTitles,
  TextWrap,
  Title,
} from "../../../../components/ReusableStyles";
import * as S from "./Balances.styles";

type BalancesProps = {
  balances: (
    | HorizonApi.BalanceLineNative
    | HorizonApi.BalanceLineAsset<"credit_alphanum4">
    | HorizonApi.BalanceLineAsset<"credit_alphanum12">
    | HorizonApi.BalanceLineLiquidityPool
  )[];
};

const Balances = (p: BalancesProps) => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <S.Header>
        <Title>{t("balances")}</Title>
      </S.Header>
      {p.balances.length < 1 ? (
        <NoDataToDisplay />
      ) : (
        <>
          <TableTitles>
            <S.AssetText>{t("asset")}</S.AssetText>
            <S.BalanceText>{t("balance")}</S.BalanceText>
            <S.LimitText>{t("limit")}</S.LimitText>
          </TableTitles>
          <ListContainer>
            {p.balances.map((balance, index) => (
              <ListRow $isLast={index === (p.balances.length || 0) - 1}>
                <S.AssetWrapper>
                  <TextWrap>
                    {balance.asset_type === "native" || balance.asset_type === "liquidity_pool_shares" ? (
                      <Asset type={balance.asset_type} />
                    ) : (
                      <Asset
                        type={balance.asset_type}
                        code={balance.asset_code}
                        issuerAddress={balance.asset_issuer}
                      />
                    )}
                  </TextWrap>
                </S.AssetWrapper>
                <S.BlockWrapper>
                  <TextWrap>{new BigNumber(balance.balance).toString()}</TextWrap>
                </S.BlockWrapper>
                <S.LimitWrapper>
                  <TextWrap>{balance.asset_type !== "native" && balance.limit}</TextWrap>
                </S.LimitWrapper>
              </ListRow>
            ))}
          </ListContainer>
        </>
      )}
    </MainContainer>
  );
};

export default Balances;
