import React from "react";
import { useTranslation } from "react-i18next";

import { useGetTrades } from "../data/services/useGetTrades";
import ExportCsv from "./ExportCSV/ExportCSV";
import LoaderWrapper from "./LoaderWrapper/LoaderWrapper";
import PagePagination from "./PagePagination";
import { MainContainer, TableHeader, Title } from "./ReusableStyles";
import TradesTable from "./TradesTable/TradesTable";

const DEFAULT_MAX_TRADES = 10;

type TradesProps = {
  maxTradesPerPage?: number;
  accountId: string;
};

const Trades = (p: TradesProps) => {
  const {
    trades,
    setTrades,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  } = useGetTrades({ tradesLimit: p.maxTradesPerPage ?? DEFAULT_MAX_TRADES, accountId: p.accountId });

  const { t } = useTranslation();

  return (
    <MainContainer>
      <TableHeader>
        <Title>{t("trades")}</Title>
      </TableHeader>
      <LoaderWrapper
        loading={!trades || isLoading}
        fetchingError={fetchingError}
        collectionEmpty={trades && trades.records.length < 1}
      >
        <PagePagination
          maxRecordsPerPage={p.maxTradesPerPage ?? DEFAULT_MAX_TRADES}
          data={trades}
          setData={setTrades}
          page={page}
          setPage={setPage}
          fetchingError={fetchingError}
          setFetchingError={setFetchingError}
          server={server}
          setIsLoading={setIsLoading}
        >
          <TradesTable trades={trades} />
        </PagePagination>
      </LoaderWrapper>
      {trades && trades.records.length > 0 && (
        <ExportCsv recordsType={"tradesForAccount"} recordId={p.accountId} />
      )}
    </MainContainer>
  );
};

export default Trades;
