import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { LedgerCallBuilder } from "@stellar/stellar-sdk/lib/horizon/ledger_call_builder";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStellarContext } from "../../contexts/StellarContextProvider";

export const useGetBlocks = (blocksLimit?: number) => {
  const { stellarServer } = useStellarContext();

  const [server, setServer] = useState<LedgerCallBuilder | undefined>();
  const [blocks, setBlocks] = useState<ServerApi.CollectionPage<ServerApi.LedgerRecord> | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    const baseServer = stellarServer?.ledgers();
    setServer(baseServer);
  }, [stellarServer]);

  useEffect(() => {
    setIsLoading(true);
    setBlocks(undefined);
    setFetchingError(null);

    (async () => {
      try {
        const fetchedBlocks = await server
          ?.limit(blocksLimit ?? 10)
          .order("desc")
          .call();

        setBlocks(fetchedBlocks);
      } catch (error: any) {
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();

    setPage(1);
  }, [blocksLimit, server, t]);

  return {
    blocks,
    setBlocks,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  };
};
