import React from "react";
import { useTranslation } from "react-i18next";

import { useGetEffects } from "../data/services/useGetEffects";
import EffectsTable from "./EffectsTable/EffectsTable";
import ExportCsv from "./ExportCSV/ExportCSV";
import LoaderWrapper from "./LoaderWrapper/LoaderWrapper";
import PagePagination from "./PagePagination";
import { MainContainer, TableHeader, Title } from "./ReusableStyles";

const DEFAULT_MAX_EFFECTS = 10;

type EffectsProps = {
  accountId: string;
  maxEffectsPerPage?: number;
};

const Effects = (p: EffectsProps) => {
  const {
    effects,
    setEffects,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  } = useGetEffects({ effectsLimit: p.maxEffectsPerPage ?? DEFAULT_MAX_EFFECTS, accountId: p.accountId });

  const { t } = useTranslation();

  return (
    <MainContainer>
      <TableHeader>
        <Title>{t("effects")}</Title>
      </TableHeader>
      <LoaderWrapper
        loading={!effects || isLoading}
        fetchingError={fetchingError}
        collectionEmpty={effects && effects.records.length < 1}
      >
        <PagePagination
          maxRecordsPerPage={p.maxEffectsPerPage ?? DEFAULT_MAX_EFFECTS}
          data={effects}
          setData={setEffects}
          page={page}
          setPage={setPage}
          fetchingError={fetchingError}
          setFetchingError={setFetchingError}
          server={server}
          setIsLoading={setIsLoading}
        >
          <EffectsTable effects={effects} />
        </PagePagination>
      </LoaderWrapper>
      {effects && effects.records.length > 0 && (
        <ExportCsv recordsType={"effectsForAccount"} recordId={p.accountId} />
      )}
    </MainContainer>
  );
};

export default Effects;
