import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(10)};

  @media (max-width: 880px) {
    justify-content: center;
  }
`;

export const TabButton = styled.p<{ $active?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.palette.themeColors.fonts.secondaryTitle};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.themeColors.fonts.headerHover};
  }

  ${({ theme, $active }) => $active && `color: ${theme.palette.themeColors.fonts.headerHover}`};
`;
