import React from "react";
import { useTranslation } from "react-i18next";

import ExportCsv from "../components/ExportCSV/ExportCSV";
import LoaderWrapper from "../components/LoaderWrapper/LoaderWrapper";
import PagePagination from "../components/PagePagination";
import PaymentsTable from "../components/PaymentsTable/PaymentsTable";
import { MainContainer, TableHeader, Title } from "../components/ReusableStyles";
import { useGetPayments } from "../data/services/useGetPayments";

const DEFAULT_MAX_PAYMENTS = 20;

type PaymentsProps = {
  maxPaymentsPerPage?: number;
  accountId?: string;
};

const Payments = (p: PaymentsProps) => {
  const {
    payments,
    setPayments,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  } = useGetPayments(p.maxPaymentsPerPage ?? DEFAULT_MAX_PAYMENTS, p.accountId);

  const { t } = useTranslation();

  return (
    <MainContainer>
      <TableHeader>
        <Title>{t("payments")}</Title>
      </TableHeader>
      <LoaderWrapper
        loading={isLoading}
        fetchingError={fetchingError}
        collectionEmpty={payments && payments.records.length < 1}
      >
        <PagePagination
          maxRecordsPerPage={p.maxPaymentsPerPage ?? DEFAULT_MAX_PAYMENTS}
          data={payments}
          setData={setPayments}
          page={page}
          setPage={setPage}
          fetchingError={fetchingError}
          setFetchingError={setFetchingError}
          server={server}
          setIsLoading={setIsLoading}
        >
          <PaymentsTable payments={payments} />
        </PagePagination>
      </LoaderWrapper>
      {payments && payments.records.length > 0 && (
        <ExportCsv recordsType={p.accountId ? "paymentsForAccount" : "payments"} recordId={p.accountId} />
      )}
    </MainContainer>
  );
};

export default Payments;
