import React from "react";
import { useParams } from "react-router-dom";

import BlocksPanel from "./components/BlocksPanel";
import MainnetMetrics from "./components/MainnetMetrics/MainnetMetrics";
import OperationsPanel from "./components/OperationsPanel/OperationsPanel";
import TransactionsPanel from "./components/TransactionsPanel";
import * as S from "./Home.styles";

const Home = () => {
  const { environment } = useParams<{ environment: string }>();

  return (
    <S.WrapperContainer>
      {environment === "mainnet" && <MainnetMetrics />}
      <S.ColumnsPositioner>
        <S.LeftColumn>
          <OperationsPanel />
        </S.LeftColumn>
        <S.RightColumn>
          <TransactionsPanel />
          <BlocksPanel />
        </S.RightColumn>
      </S.ColumnsPositioner>
    </S.WrapperContainer>
  );
};

export default Home;
