import React from "react";
import { useTranslation } from "react-i18next";

import { MainContainer } from "../../components/ReusableStyles";
import * as S from "./PageNotFound.styles";

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <S.PositionerContainer>
        <S.ErrorImage />
        <S.ErrorTitle>{t("page.not.found")}</S.ErrorTitle>
        <S.ErrorDescription>{t("page.not.found.description")}</S.ErrorDescription>
        <S.TipsTitle>{t("page.not.found.tips.title")}</S.TipsTitle>
        <S.Tip>• {t("page.not.found.tip1")}</S.Tip>
        <S.Tip>• {t("page.not.found.tip2")}</S.Tip>
      </S.PositionerContainer>
    </MainContainer>
  );
};

export default PageNotFound;
