import React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  ContentContainer,
  FakeInput,
  ModalTitle,
  OpenSearchImage,
  Section,
  SectionDescription,
  SectionTitle,
} from "./SearchInfoModalContent.styles";

const DUMMY_ACCOUNT_ID = "GBUZFSIMVRQXNOZAQGQSGGG9SUM4WPFCS4X7664AZFFBGAO7OKWTNRI5";
const DUMMY_TRANSACTION_HASH = "ad67d3fba0474bd0ece7c0c30716abbd7cc3843c021d911ec00a85d53f75dfa8";
const DUMMY_BLOCK_NUMBER = "16769676";

const SearchInfoModalContent = () => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <ModalTitle>{t("search.by").toUpperCase()}</ModalTitle>
      <Section>
        <SectionTitle>{t("account.id")}</SectionTitle>
        <SectionDescription>{t("also.called.public.key.public.address")}</SectionDescription>
        <FakeInput value={DUMMY_ACCOUNT_ID} />
      </Section>
      <Section>
        <SectionTitle>
          {t("transaction")} {t("hash")}
        </SectionTitle>
        <FakeInput value={DUMMY_TRANSACTION_HASH} />
      </Section>
      <Section>
        <SectionTitle>{t("ledger")}</SectionTitle>
        <FakeInput value={DUMMY_BLOCK_NUMBER} />
      </Section>
      <ModalTitle $additionalMarginTop>{t("open.search").toUpperCase()}</ModalTitle>
      <SectionDescription>
        <Trans
          i18nKey="open.search.description"
          components={{
            link: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://developer.mozilla.org/en-US/docs/Web/OpenSearch"
              >
                {t("open.search")}
              </a>
            ),
          }}
        />
      </SectionDescription>
      <OpenSearchImage />
    </ContentContainer>
  );
};

export default SearchInfoModalContent;
