import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import StellarSdk from "../data/stellarInit";

export type GlobalContextType = {
  stellarServer: StellarSdk.Horizon.Server | null;
  screenWidth: number;
};

const StellarContext = createContext<GlobalContextType | null>(null);

type StellarContextProviderProps = PropsWithChildren<{}>;

const testnetServerUrl = "https://api.testnet.minepi.com";
const testnet2ServerUrl = "https://api.testnet2.minepi.com/";
const mainnetServerUrl = "https://api.mainnet.minepi.com";

const serverUrl = (environment?: string) => {
  switch (environment) {
    case "testnet":
      return testnetServerUrl;
    case "testnet2":
      return testnet2ServerUrl;
    case "mainnet":
      return mainnetServerUrl;
    default:
      return mainnetServerUrl;
  }
};

export const StellarContextProvider = ({ children }: StellarContextProviderProps) => {
  const { environment } = useParams<{ environment: string }>();

  const [stellarServer, setStellarServer] = useState<StellarSdk.Horizon.Server | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const server = new StellarSdk.Horizon.Server(serverUrl(environment));

    setStellarServer(server);
  }, [environment]);

  const value = {
    stellarServer,
    screenWidth,
  };

  return <StellarContext.Provider value={value}>{children}</StellarContext.Provider>;
};

export const useStellarContext = () => {
  const context = useContext(StellarContext);
  if (context) return context;

  throw Error("Use this hook in GlobalContext scope");
};
