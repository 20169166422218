import { StrKey } from "@stellar/stellar-sdk";
import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React from "react";
import { useTranslation } from "react-i18next";

import AccountLink from "../../../../components/AccountLink/AccountLink";
import NoDataToDisplay from "../../../../components/NoDataToDisplay/NoDataToDisplay";
import {
  FlexOneText,
  FlexOneWrapper,
  ListContainer,
  ListRow,
  MainContainer,
  TableTitles,
  TextWrap,
  Title,
} from "../../../../components/ReusableStyles";
import * as S from "./Signing.styles";

type SigningProps = {
  signers: ServerApi.AccountRecord["signers"];
  thresholds: ServerApi.AccountRecord["thresholds"];
};

const Signing = (p: SigningProps) => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <S.Header>
        <Title>{t("signing")}</Title>
      </S.Header>
      {p.signers.length < 1 ? (
        <NoDataToDisplay />
      ) : (
        <>
          <TableTitles>
            <FlexOneText>{t("key")}</FlexOneText>
            <FlexOneText>{t("weight")}</FlexOneText>
            <FlexOneText>{t("type")}</FlexOneText>
          </TableTitles>
          <ListContainer>
            {p.signers.map((signer, index) => (
              <ListRow $isLast={index === (p.signers.length || 0) - 1}>
                <FlexOneWrapper>
                  {signer.type === "ed25519_public_key" && (
                    <AccountLink variant="text" accountAddress={signer.key} />
                  )}
                  {signer.type === "sha256_hash" && StrKey.decodeSha256Hash(signer.key).toString("hex")}
                  {signer.type === "preauth_tx" && StrKey.decodePreAuthTx(signer.key).toString("hex")}
                </FlexOneWrapper>
                <FlexOneWrapper>
                  <TextWrap>{signer.weight}</TextWrap>
                </FlexOneWrapper>
                <FlexOneWrapper>
                  <TextWrap>{signer.type}</TextWrap>
                </FlexOneWrapper>
              </ListRow>
            ))}
          </ListContainer>
          <S.SmallHeader>
            <Title $medium>{t("thresholds")}</Title>
          </S.SmallHeader>
          <TableTitles>
            <FlexOneText>{t("threshold.low")}</FlexOneText>
            <FlexOneText>{t("threshold.medium")}</FlexOneText>
            <FlexOneText>{t("threshold.high")}</FlexOneText>
          </TableTitles>
          <ListContainer>
            <ListRow $isLast>
              <FlexOneWrapper>{p.thresholds.low_threshold}</FlexOneWrapper>
              <FlexOneWrapper>{p.thresholds.med_threshold}</FlexOneWrapper>
              <FlexOneWrapper>{p.thresholds.high_threshold}</FlexOneWrapper>
            </ListRow>
          </ListContainer>
        </>
      )}
    </MainContainer>
  );
};

export default Signing;
