import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { MainContainer } from "../ReusableStyles";
import { ErrorTitle, NoteIcon, PageDoesntExist, PositionerContainer } from "./NoDataToDisplay.styles";

type NoDataToDisplayProps = {
  errorTitle?: string;
};

const NoDataToDisplay = (p: NoDataToDisplayProps) => {
  const { environment } = useParams();

  const { t } = useTranslation();

  return (
    <MainContainer>
      <PositionerContainer>
        <NoteIcon />
        <ErrorTitle>{t("no.data.to.display")}</ErrorTitle>
        {p.errorTitle === "Resource Missing" && (
          <PageDoesntExist>
            {t("resource.doesnt.exist", { environment: environment?.toUpperCase() })}
          </PageDoesntExist>
        )}
      </PositionerContainer>
    </MainContainer>
  );
};

export default NoDataToDisplay;
