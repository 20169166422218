import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { TradesCallBuilder } from "@stellar/stellar-sdk/lib/horizon/trades_call_builder";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStellarContext } from "../../contexts/StellarContextProvider";

export const useGetTrades = ({ accountId, tradesLimit }: { accountId: string; tradesLimit: number }) => {
  const { stellarServer } = useStellarContext();

  const [server, setServer] = useState<TradesCallBuilder | undefined>();
  const [trades, setTrades] = useState<ServerApi.CollectionPage<ServerApi.TradeRecord> | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    const baseServer = stellarServer?.trades().forAccount(accountId || "");

    setServer(baseServer);
  }, [stellarServer, accountId]);

  useEffect(() => {
    setIsLoading(true);
    setTrades(undefined);
    setFetchingError(null);

    (async () => {
      try {
        const fetchedTrades = await server
          ?.limit(tradesLimit ?? 10)
          .order("desc")
          .call();

        setTrades(fetchedTrades);
      } catch (error: any) {
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();

    setPage(1);
  }, [tradesLimit, server, t]);

  return {
    trades,
    setTrades,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  };
};
