import React, { ReactNode } from "react";

import { ButtonContainer, ButtonLabel } from "./CustomButton.styles";

export type ButtonVariant = "primary" | "secondary" | "viewAll" | "search";

type CustomButtonProps = {
  label?: string;
  icon?: ReactNode;
  variant?: ButtonVariant;
  onClick?: () => void;
  active?: boolean;
};

const CustomButton = (p: CustomButtonProps) => {
  return (
    <ButtonContainer variant={p.variant} onClick={p.onClick} $active={p.active}>
      <ButtonLabel variant={p.variant}>
        {p.icon}
        {p.label}
      </ButtonLabel>
    </ButtonContainer>
  );
};

export default CustomButton;
