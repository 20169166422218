import styled from "styled-components";

import { ButtonVariant } from "./CustomButton";

export const ButtonContainer = styled.span<{ variant?: ButtonVariant; $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(6)}`};
  border: 1px solid ${({ theme }) => theme.palette.themeColors.borders.buttonPrimary};
  color: ${({ theme }) => theme.palette.themeColors.fonts.secondary};
  border-radius: 60px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.themeColors.backgrounds.buttonPrimaryActive};

    ${({ theme, variant }) => {
      if (variant === "viewAll")
        return `background-color: ${theme.palette.themeColors.backgrounds.vieAllHover}`;
      if (variant === "secondary")
        return `background-color: ${theme.palette.themeColors.backgrounds.buttonSecondaryHover}`;
      if (variant === "search")
        return `
          border: 1px solid ${theme.palette.themeColors.borders.buttonSearchHover};
          background-color: ${theme.palette.themeColors.backgrounds.buttonSearch};
        `;
    }}
  }

  ${({ theme, $active }) =>
    $active && `background-color: ${theme.palette.themeColors.backgrounds.buttonPrimaryActive}`}

  ${({ theme, variant }) => {
    if (variant === "secondary")
      return `
        border: none;
        background-color: ${theme.palette.themeColors.backgrounds.buttonSecondary};
        color: ${theme.palette.themeColors.fonts.buttonSecondary}
      `;
    if (variant === "viewAll")
      return `
        padding: ${theme.spacing(2)} ${theme.spacing(6)};
        color: ${theme.palette.themeColors.fonts.viewAllButton};
        min-width: 88px;
      `;
    if (variant === "search")
      return `
        border: 1px solid ${theme.palette.themeColors.borders.buttonSearch};
        background-color: ${theme.palette.themeColors.backgrounds.buttonSearch};
        color: ${theme.palette.themeColors.fonts.buttonSearch}
        border-radius: 50%;
      `;
  }}
`;

export const ButtonLabel = styled.p<{ variant?: ButtonVariant }>`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.neutral};
  gap: ${({ theme }) => theme.spacing(2)};
`;
