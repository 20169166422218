import styled from "styled-components";

import { ReactComponent as LanguageChangeIconImage } from "../../assets/icons/languageSelect.svg";
import { NAV_BAR_HEIGHT } from "../Layout/components/Header/Header.styles";

export const LanguagePickerContainer = styled.div``;

type LanguageProps = {
  $isLangListOpen: boolean;
};

export const LanguageIconWrapper = styled.div<LanguageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0.7px solid
    ${({ theme, $isLangListOpen }) =>
      $isLangListOpen
        ? theme.palette.themeColors.borders.hamburgerMenuFocus
        : theme.palette.themeColors.borders.hamburgerBorder};
  padding: ${({ theme }) =>
    `${theme.spacing(3)} ${theme.spacing(3.5)} ${theme.spacing(3)} ${theme.spacing(3.5)}`};

  ${({ theme, $isLangListOpen }) =>
    $isLangListOpen && `background-color: ${theme.palette.themeColors.backgrounds.hamburgerMenuFocus}`}
`;

export const LanguageChangeIcon = styled(LanguageChangeIconImage)<LanguageProps>`
  width: ${({ theme }) => theme.spacing(12)};
  height: ${({ theme }) => theme.spacing(12)};

  fill: ${({ theme, $isLangListOpen }) =>
    $isLangListOpen
      ? theme.palette.themeColors.borders.hamburgerMenuFocus
      : theme.palette.themeColors.fonts.hamburgerMenu};
`;

export const LanguagesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: ${({ theme }) => theme.spacing(90)};
  max-height: 100%;
  overflow-y: scroll;
  background: ${({ theme }) => theme.palette.themeColors.backgrounds.languagesList};
  position: absolute;
  top: ${NAV_BAR_HEIGHT - 3}px;
  right: 0;
  padding: ${({ theme }) => theme.spacing(6)} ${({ theme }) => theme.spacing(10)};
  border-top: 0.5px solid ${({ theme }) => theme.palette.themeColors.borders.hamburgerMenuDivider};
  z-index: 101;

  @media (max-width: 1000px) {
    top: ${NAV_BAR_HEIGHT}px;
  }

  @media (max-height: 720px) {
    max-height: 75%;
  }
`;

export const Language = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.palette.themeColors.fonts.secondary};
  padding: ${({ theme }) => theme.spacing(3)} 0;
  margin: 0;
`;
