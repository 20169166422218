import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CopyIconButton from "../components/CopyIconButton/CopyIconButton";
import CustomInternalLink from "../components/CustomInternalLink/CustomInternalLink";
import JsonButton from "../components/JSONButton/JSONButton";
import LoaderWrapper from "../components/LoaderWrapper/LoaderWrapper";
import {
  ContentContainer,
  FlexStartRow,
  Info,
  Table,
  TableHeader,
  TableRow,
  TablesContainer,
  Title,
  TitleItemNumber,
  WrapperContainer,
} from "../components/ReusableStyles";
import { useGetSingleBlock } from "../data/services/useGetSingleBlock";
import { formatDateAndTime } from "../utils/formatDateAndTIme";
import { shortenString } from "../utils/links";
import Transactions from "./Transactions";

const MAX_TRANSACTIONS_PER_PAGE = 10;

const Block = () => {
  const { environment, blockId } = useParams<{ environment: string; blockId: string }>();

  const { t } = useTranslation();

  const { block, isLoading, fetchingError } = useGetSingleBlock(blockId);

  const currency = () => (environment === "mainnet" ? "π" : "Test-π");

  if (!block || isLoading || fetchingError)
    return <LoaderWrapper loading={isLoading} fetchingError={fetchingError} />;

  return (
    <WrapperContainer>
      <ContentContainer>
        <TableHeader>
          <FlexStartRow>
            <Title>{t("ledger")}</Title>
            <TitleItemNumber>
              {blockId} <CopyIconButton value={blockId || ""} />
            </TitleItemNumber>
          </FlexStartRow>
          <JsonButton type="ledgers" recordId={blockId} />
        </TableHeader>
        <TablesContainer>
          <Table>
            <TableRow>
              <Info>{t("time")}</Info>
              <Info>{formatDateAndTime(block.closed_at)}</Info>
            </TableRow>
            <TableRow>
              <Info>{t("hash")}</Info>
              <Info>{shortenString(block.hash, 20)}</Info>
            </TableRow>
            <TableRow>
              <Info>{t("prevHash")}</Info>
              <Info>
                <CustomInternalLink
                  to={`/${environment}/blocks/${block.sequence - 1}`}
                  title={block.prev_hash}
                >
                  {shortenString(block.prev_hash, 20)}
                </CustomInternalLink>
              </Info>
            </TableRow>
            <TableRow>
              <Info>{t("operations")}</Info>
              <Info>{block.operation_count}</Info>
            </TableRow>
            <TableRow>
              <Info>{t("transactions.failed")}</Info>
              <Info>{block.failed_transaction_count}</Info>
            </TableRow>
            <TableRow $last>
              <Info>{t("max.transactions")}</Info>
              <Info>
                {block.max_tx_set_size} {t("per.block")}
              </Info>
            </TableRow>
          </Table>
          <Table>
            <TableRow>
              <Info>{t("base.fee")}</Info>
              <Info>
                {block.base_fee_in_stroops} {currency()}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{t("base.reserve")}</Info>
              <Info>
                {block.base_reserve_in_stroops} {currency()}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{t("fee.pool")}</Info>
              <Info>
                {block.fee_pool} {currency()}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{t("total.coins")}</Info>
              <Info>
                {block.total_coins} {currency()}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{t("protocolVersion")}</Info>
              <Info>{block.protocol_version}</Info>
            </TableRow>
          </Table>
        </TablesContainer>
      </ContentContainer>
      {block.successful_transaction_count > 0 && (
        <Transactions
          maxTransactionsPerPage={MAX_TRANSACTIONS_PER_PAGE}
          hideBlocks
          transactionsNumber={block.successful_transaction_count}
          recordId={blockId}
          transactionsFor="block"
        />
      )}
    </WrapperContainer>
  );
};

export default Block;
