import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import { OperationCallBuilder } from "@stellar/stellar-sdk/lib/horizon/operation_call_builder";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStellarContext } from "../../contexts/StellarContextProvider";

export type OperationsFor = "transaction" | "account";

type GetOperationsProps = {
  operationsLimit?: number;
  recordId?: string;
  operationsFor?: OperationsFor;
};

export const useGetOperations = ({ operationsLimit, recordId, operationsFor }: GetOperationsProps) => {
  const { stellarServer } = useStellarContext();

  const [server, setServer] = useState<OperationCallBuilder | undefined>();
  const [operations, setOperations] = useState<
    ServerApi.CollectionPage<ServerApi.OperationRecord> | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    const baseServer = stellarServer?.operations();
    const transactionOperationsServer = stellarServer?.operations().forTransaction(recordId || "");
    const accountOperationsServer = stellarServer?.operations().forAccount(recordId || "");

    let pickedServer = baseServer;
    if (operationsFor === "transaction") pickedServer = transactionOperationsServer;
    if (operationsFor === "account") pickedServer = accountOperationsServer;

    setServer(pickedServer);
  }, [operationsFor, recordId, stellarServer]);

  useEffect(() => {
    setIsLoading(true);
    setOperations(undefined);
    setFetchingError(null);

    (async () => {
      try {
        const fetchedOperations = await server
          ?.limit(operationsLimit ?? 25)
          .order("desc")
          .call();

        setOperations(fetchedOperations);
      } catch (error: any) {
        setFetchingError({ title: t("fetching.error"), error });
      }
      setIsLoading(false);
    })();

    setPage(1);
  }, [operationsLimit, server, t]);

  return {
    operations,
    setOperations,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  };
};
