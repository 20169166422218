import styled from "styled-components";

export const CustomButton = styled.div`
  background-color: ${({ theme }) => theme.palette.themeColors.backgrounds.jsonButton};
  color: ${({ theme }) => theme.palette.themeColors.fonts.jsonButton};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(8)}`};
  border-radius: 25px;
  cursor: pointer;
  width: 73px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.themeColors.backgrounds.jsonButtonHover};
  }
`;

export const Source = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  flex-shrink: 1;
`;

export const JsonWrapper = styled.div`
  display: flex;
  max-height: 60vh;
  border: 1px solid gray;
  border-radius: 8px;
  overflow-x: auto;
  overflow-y: scroll;
  background-color: black;
  position: relative;
`;

export const JsonPositioner = styled.div``;

export const CopyButtonPositioner = styled.div`
  position: fixed;
  top: 90px;
  right: 50px;
`;
