import styled from "styled-components";

export const TypeText = styled.p<{ $expanded?: boolean }>`
  flex: 1;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  min-width: 50px;
`;

export const DetailsText = styled(TypeText)``;

export const TransactionText = styled(TypeText)`
  flex: 0.5;

  @media (max-width: 1000px) {
    flex: 1;
  }
`;

export const TimeText = styled(TypeText)`
  flex: 0.5;

  @media (max-width: 1000px) {
    flex: 1;
  }
`;

export const JsonFileText = styled(TypeText)`
  flex: 0.5;

  @media (max-width: 1000px) {
    flex: 1;
  }
`;
