import styled from "styled-components";

import errorImage from "../../assets/images/redWarningImage.png";

export const PositionerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorImage = styled.img.attrs({
  src: errorImage,
})`
  width: 200px;
  height: 200px;
  margin-bottom: ${({ theme }) => theme.spacing(30)};
`;

export const ErrorTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(10)};
`;

export const ErrorDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(20)};
`;

export const TipsTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const Tip = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  text-align: center;
`;
