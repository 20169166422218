import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import BigNumber from "bignumber.js";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactTimeAgo from "react-time-ago";

import AccountLink from "../AccountLink/AccountLink";
import Asset from "../Asset";
import { ListContainer, ListRow, TableTitles } from "../ReusableStyles";
import * as S from "./TradesTable.styles";

type TradesTableProps = {
  trades?: ServerApi.CollectionPage<ServerApi.TradeRecord>;
};

const TradesTable = (p: TradesTableProps) => {
  const { t } = useTranslation();

  const Base = ({ trade }: { trade: ServerApi.TradeRecord }) => (
    <span>
      {new BigNumber(trade.base_amount).toString()}{" "}
      <Asset
        code={trade.base_asset_code}
        issuerAddress={trade.base_asset_issuer}
        type={trade.base_asset_type}
      />
    </span>
  );

  const Counter = ({ trade }: { trade: ServerApi.TradeRecord }) => (
    <span>
      {new BigNumber(trade.counter_amount).toString()}{" "}
      <Asset
        code={trade.counter_asset_code}
        issuerAddress={trade.counter_asset_issuer}
        type={trade.counter_asset_type}
      />
    </span>
  );

  return (
    <>
      <TableTitles>
        <S.AccountText>{t("account")} 1</S.AccountText>
        <S.BoughtText>{t("bought")}</S.BoughtText>
        <S.AccountText>{t("account")} 2</S.AccountText>
        <S.BoughtText>{t("bought")}</S.BoughtText>
        <S.TimeText>{t("time")}</S.TimeText>
      </TableTitles>
      <ListContainer>
        {p.trades?.records.map((trade, index) => {
          const baseFirst = trade.base_is_seller;

          return (
            <ListRow $isLast={index === (p.trades?.records.length || 0) - 1} key={trade.id}>
              <S.AccountWrapper>
                {trade.base_account && <AccountLink variant="button" accountAddress={trade.base_account} />}
              </S.AccountWrapper>
              <S.BoughtWrapper>
                {baseFirst ? <Base trade={trade} /> : <Counter trade={trade} />}
              </S.BoughtWrapper>
              <S.AccountWrapper>
                {trade.counter_account && (
                  <AccountLink variant="button" accountAddress={trade.counter_account} />
                )}
              </S.AccountWrapper>
              <S.BoughtWrapper>
                {baseFirst ? <Counter trade={trade} /> : <Base trade={trade} />}
              </S.BoughtWrapper>
              <S.TimeWrapper>
                <ReactTimeAgo date={new Date(trade.ledger_close_time)} locale="en-US" timeStyle="round" />
              </S.TimeWrapper>
            </ListRow>
          );
        })}
      </ListContainer>
    </>
  );
};

export default TradesTable;
