import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AccountLink from "./AccountLink/AccountLink";

type AssetProps = {
  code?: string;
  issuerAddress?: string;
  type: string;
};

const Asset = (p: AssetProps) => {
  const { environment } = useParams<{ environment: string }>();

  const [currency, setCurrency] = useState("");

  useEffect(() => {
    if (p.type !== "native" && p.code) return setCurrency(p.code);
    if (environment === "mainnet") {
      setCurrency("π");
    } else {
      setCurrency("Test-π");
    }
  }, [environment, p.code, p.type]);

  return (
    <>
      {currency}{" "}
      {p.type !== "native" && p.issuerAddress && (
        <AccountLink accountAddress={p.issuerAddress} variant="text" />
      )}
    </>
  );
};

export default Asset;
