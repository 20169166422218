import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";
import { useTheme } from "styled-components";

import NoDataToDisplay from "../NoDataToDisplay/NoDataToDisplay";
import { MainContainer } from "../ReusableStyles";
import * as S from "./LoaderWrapper.styles";
import { ErrorImage, ErrorText } from "./LoaderWrapper.styles";

type LoaderWrapperProps = {
  loading: boolean;
  fetchingError: { title: string; error: any } | null;
  children?: ReactNode;
  containerHeight?: number;
  collectionEmpty?: boolean;
};

const LoaderWrapper = (p: LoaderWrapperProps) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <>
      {p.loading ? (
        <MainContainer>
          <S.MessageContainer $height={p.containerHeight}>
            <Loader
              type="spinner-circle"
              bgColor={theme.palette.themeColors.backgrounds.spinnerBottom}
              color={theme.palette.themeColors.backgrounds.spinnerTop}
              size={100}
            />
          </S.MessageContainer>
        </MainContainer>
      ) : (
        <>
          {p.fetchingError &&
          p.fetchingError.title !== t("last.page.reached") &&
          p.fetchingError.error?.response?.title !== "Resource Missing" ? (
            <MainContainer>
              <S.MessageContainer $height={p.containerHeight}>
                <ErrorImage />
                <ErrorText>{p.fetchingError.title}</ErrorText>
              </S.MessageContainer>
            </MainContainer>
          ) : (
            <>
              {p.collectionEmpty || p.fetchingError?.error?.response?.title === "Resource Missing" ? (
                <NoDataToDisplay errorTitle={p.fetchingError?.error.response.title} />
              ) : (
                p.children
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LoaderWrapper;
