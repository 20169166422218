import styled from "styled-components";

export const HashMark = styled.p`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const BlockText = styled(HashMark)``;

export const TimeText = styled(HashMark)``;

export const HashWrapper = styled.div`
  flex: 1;
`;

export const BlockWrapper = styled(HashWrapper)``;

export const TimeWrapper = styled(HashWrapper)``;
